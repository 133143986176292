import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import html2pdf from 'html2pdf.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReactToPrint } from 'react-to-print';

export default function QRCodeGenerator() {
 // const [club, setClub] = useState([]);
  const [listeLicences, setListeLicences] = useState([]);
  const navigate = useNavigate();
  const { data } = useParams();
  const { REACT_APP_API_URL } = process.env;
  const url = REACT_APP_API_URL
  const parsedData = JSON.parse(decodeURIComponent(data));

  const [idlic,setidlic]=useState()

  const listSaison = JSON.parse(localStorage.getItem('listsaison'))

  useEffect(() => {
    const fetchLicences = async () => {
      const certificateUrl = `${process.env.REACT_APP_API_FRONT_URL}/Certificats/${encodeURIComponent(JSON.stringify(parsedData))}`;
      parsedData.certificateUrl = certificateUrl;
      const respdiscipline = await Getdiscipline();
  
      const updatedListeLicences = [];
  
      // Use Promise.all to handle asynchronous operations inside the loop
      await Promise.all(respdiscipline.data.data.map(async element => {
        if (parsedData[element.libellerFR] !== null) {
          const updatedParsedData = { ...parsedData };
          updatedParsedData.numLicence = parsedData[element.libellerFR];
          updatedParsedData.spec = element['LibellerAR'];
          updatedParsedData.specFr = parsedData[`${element.libellerFR}_valide`];
          updatedParsedData.cat_age = parsedData[`${[element.libellerFR]}_CA`];
          updatedParsedData.QRdata = {
            nom: parsedData.nom,
            prenom: parsedData.prenom,
            id:parsedData.id,
            idClub:parsedData.idClub,
            numlicence: parsedData[element.libellerFR],
            libellerFR:element.libellerFR,
            valide: updatedParsedData.specFr,
            saison:listSaison.find(item=>parseInt(item.id) === parseInt(parsedData.saison))?.libelle
          };
         
          updatedListeLicences.push(updatedParsedData);
        }
      }));
  
      setListeLicences(updatedListeLicences);
    };
  
    fetchLicences();
  }, [parsedData]);
  

  const handleButtonClick = item => {
    const newTab = window.open(`${process.env.REACT_APP_API_FRONT_URL}Certificats/${encodeURIComponent(JSON.stringify(item.QRdata))}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      toast.error('منع فتح نافذة جديدة');
    }
  };

  const handlePrint = useReactToPrint({
    
    content: () => document.getElementById(idlic),
  });
  const download = item => {
  setidlic(`pdf-content${item.numLicence}`)
  handlePrint()
  };

  return (
    <div>

      {listeLicences.map(item => (
        <div key={item.numLicence}>
          <button onClick={() => download(item)}>تحميل</button>
          <div>
          <div id={`pdf-content${item.numLicence}`}>
        
            <div className='licence' style={{backgroundColor:"white", height:'100vh !important'}}>
              <div className="certificate" id={`pdf-content${item.numLicence}`}  style={{backgroundColor:"white" }}>
                {/* Insert the rest of the certificate content here */}
                <div className='header'>
                  <div className="info-container">
                    <div className="arabic-info"></div>
                    <div className="image-container">
                    <h1> {item.specFr === 0  ?'وصل '  : null }اجازة رياضي {item.specFr !== 0  ?'رقمية '  : null }</h1>

                      <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
                    </div>
                    <div >
                      <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />
                    </div>
                   </div>
                </div>
                <div className="content">
                  <img src={url+item.image} style={{ width: '100px', height: '101px' }} />
                  <h2>{item.numLicence}</h2>
                  <p><strong>إسم واللقب </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nom} &nbsp;&nbsp; {item.prenom}</p>
                  { item.cin !== 0 ? (    <p> <strong> رقم بطاقة الهوية </strong>   :  &nbsp;&nbsp; &nbsp;&nbsp;{item.cin}  </p>):null}
                  <p><strong>تاريخ الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{new Date(item.datenaissance).getFullYear()}-{(new Date(item.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(item.datenaissance).getDate().toString().padStart(2, '0')} </p>
                  
                  <p><strong> مكان الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.lieunaissance}</p>
                  <p><strong>  الجنسية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nationalite}</p>
                  <p><strong>  إسم الجمعية أو النادي </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{localStorage.getItem('clubName') }</p>
                  <p><strong>الاختصاص </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.spec}</p>
                  <p><strong>الفئة العمرية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.cat_age}</p>
                </div>
               
                { item.specFr > 0 ?(
                <div>
                  <QRCode value={process.env.REACT_APP_API_FRONT_URL+'Certificats/'+encodeURIComponent(JSON.stringify(item.QRdata))} size={128} />
                  <button onClick={() => handleButtonClick(item)}></button>
                </div>):null}
              </div>
            </div>
         
        </div>
        </div>
        </div>
      ))}
    </div>
  );
}

/*
  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getclub();
      if (clubResp.status === 200) {
        setClub(clubResp.data.data[0]);
        localStorage.setItem('clubName', clubResp.data.data[0].nomClub);
      } else {
        alert('No federation found');
      }
    };
    fetchClubs();
  }, []);*/
