import React, { useEffect, useState } from 'react'
import { getFederation } from '../Service/Federation/FederationApi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { GetSaison } from '../Service/Saison/Saison.service';

export default function Header() {
  const [federation, setfederation] = useState([]);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const fetchfederation = async () => {
      const federationResp = await getFederation();
      if (federationResp.status === 200) {
        setfederation(federationResp.data.data);
      } else {
        alert("no federation found")
      }

    }
    fetchfederation();
  }, []);
  useEffect(() => {
    const fetchSaison = async () => {
      const resSaison = await GetSaison();
      if (resSaison.status === 200) {
        const listsaison = resSaison?.data?.data;
  
        // Convert and store the list in localStorage
        localStorage.setItem('listsaison', JSON.stringify(listsaison));
  
        // Find and store the active season as an object
        const saisonactive = listsaison.find(item => item.active === 1);
        if (saisonactive) {
          localStorage.setItem('saison_active', JSON.stringify(saisonactive));
        }
  
        // Find and store the previous season as an object
        const saisonprecedent = listsaison.find(item => item.precedente === 1);
        if (saisonprecedent) {
          localStorage.setItem('saison_precedente', JSON.stringify(saisonprecedent));
        }
      }
    };
  
    fetchSaison();
  }, []);
  

  return (

    <header className='header'>

      <div className="info-container">
        <div className="arabic-info">
          <p>{federation.nomAr}</p>
          <p>{federation.adresseAr}</p>
          <p>الهاتف / الفاكس: {federation.telfax}</p>
          <p> البريد إلكتروني :{federation.email}</p>
        </div>
        <div className="image-container">
          <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />
        </div>
        <div className="french-info">
          <p>{federation.nomFr}</p>
          <p>{federation.adresseFr}</p>
          <p>Tél./Fax : {federation.telfax}</p>
          <p>Email :{federation.email}</p>
        </div>
      </div>

    </header>

  )
}
