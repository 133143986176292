import React, { useEffect, useRef, useState } from 'react';
import { getclub, getclubNumInscription, renouvellementClub, updateClub } from '../../Service/Inscription/ClubInscription/clubinscription';
import 'font-awesome/css/font-awesome.min.css';
import { listeGouvernorat } from '../../utils/gouvernorat';
import { useNavigate } from "react-router-dom";
import { uploadimage } from '../../Service/UploadImage/UploadImage';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import Uploadfichier from '../uploadficher/uploadfichier';
import { getcountpdfs, getpdfs } from '../../Service/ImportPdfs/ImportPdfsApi';
import { getAllfiles, setNotification } from '../../Service/Federation/FederationApi';
import Spinner from 'react-bootstrap/Spinner';
import { GetSaison } from '../../Service/Saison/Saison.service';
import { toast, ToastContainer } from 'react-toastify';

export default function Homeclub() {
  const { REACT_APP_API_URL } = process.env;

  const url = REACT_APP_API_URL
  const navigate = useNavigate();
  const [club, setclub] = useState([]);
  const [clubinfo, setclubinfo] = useState([]);

  const [specialite, setdecipline] = useState([]);
  let choisi = [];
  const id = localStorage.getItem('idunique');
  const [pdfsinfo, setpdfsinfo] = useState([]);
  const [popupvalide, setpopupvalide] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [listespec, setlistespec] = useState([]);
  let chaine = '';
  const [verif, setVerif] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
  });
  const [files, setfiles] = useState([]);
  const listSaison = JSON.parse(localStorage.getItem('listsaison'))
  const [listClubSaison, setllistClubSaison] = useState([]);
  const activesaison = JSON.parse(localStorage.getItem('saison_active'))
  const saisonprecedent = JSON.parse(localStorage.getItem('saison_precedente'))

  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getclubNumInscription();
      if (clubResp.status === 200) {


        const clublist = clubResp.data.data
        setllistClubSaison(clublist)
        // find CLUB ACTIVE SAISON OU BIEN PRECEDENTE
        const findClubSaisonActive = clublist.find(item => item.saison === activesaison?.id)

        if (findClubSaisonActive) {
          setlistespec(findClubSaisonActive.specialite)

          setclub(findClubSaisonActive);
          localStorage.setItem('clubName', findClubSaisonActive.nomClub)
          localStorage.setItem('validation', findClubSaisonActive.valide)
          localStorage.setItem('setpInscription', findClubSaisonActive.stepInscription)


        } else {
          const findClubSaisonPrecedent = clublist.find(item => item.saison === saisonprecedent?.id)
          console.log("findClubSaisonPrecedent", findClubSaisonPrecedent)
          if (findClubSaisonPrecedent) {
            setlistespec(findClubSaisonPrecedent.specialite)

            setclub(findClubSaisonPrecedent);
            localStorage.setItem('clubName', findClubSaisonPrecedent.nomClub)
            localStorage.setItem('validation', findClubSaisonPrecedent.valide)
            localStorage.setItem('setpInscription', findClubSaisonPrecedent.stepInscription)


          }
        }



      } else {
        alert("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchClubs();
  }, []);

  useEffect(() => {
    const fetchDiscipline = async () => {
      const respdiscipline = await Getdiscipline();
      if (respdiscipline.status === 200) {
        setdecipline(respdiscipline.data.data);


      } else {
        alert("نحن آسفون، يبدو أن هناك مشكلة")
      }

    }
    fetchDiscipline();
  }, []);
  useEffect(() => {
    const fetchpdfs = async () => {
      const PdfsResp = await getpdfs(club.saison);
      if (PdfsResp.status === 200) {
        setpdfsinfo(PdfsResp.data.data);


      } else {
        alert("no pdfs found")
      }

    }
    fetchpdfs();
  }, []);
  useEffect(() => {
    const countpdf = async () => {
      const clubResp = await getcountpdfs(club?.saison);

      if (clubResp.status === 200) {
        const updatedVerif = { ...verif }; // Create a copy of verif
        clubResp.data.data.forEach(element => {
          switch (element.type) {
            case "مطلب_إنخراط":
              updatedVerif[0] = element.path;
              break;
            case "القانون_الأساسي_للجمعية":
              updatedVerif[1] = element.path;
              break;
            case "سخة_الإشهار_بالرائد":
              updatedVerif[2] = element.path;
              break;

            case "نسخة_قانونية_عقد_التأمين":
              updatedVerif[3] = element.path;

              break;
            case "نسخة_من_التقريرين_الأدبي_والمالي":
              updatedVerif[4] = element.path;
              break;
            case "قائمة_تركيبة_الهيئة_المديرة":
              updatedVerif[5] = element.path;
              break;
            case "إستمارة_الإشتراك":
              updatedVerif[6] = element.path;
              break;
            case "جدول_أوقات_التمارين":
              updatedVerif[7] = element.path;
              break;

            case "ترخيص_في_إستغلال_قاعة":
              updatedVerif[8] = false;
              break;

            case "قائمة_مفصلة_في_التجهيزات":
              updatedVerif[9] = false;
              break;
            case "كراس_الشروط":
              updatedVerif[10] = false;
              break;


            default:
              break;
          }
        });

        setVerif(updatedVerif); // Update verif state with the modified values
      } else {
        alert("no club found");
      }
    };

    countpdf();
  }, [club]);
  const downlodcahiedecharge = () => {
    const newTab = window.open(`${url}uploadfile/download`, '_blank');
    if (newTab) {
      // If the new tab was successfully opened, you can focus on it (optional)
      newTab.focus();
    } else {
      alert('منع فتح نافذة جديدة');
    }
  };
  useEffect(() => {
    const fetchfiles = async () => {
      const fileResp = await getAllfiles();
      if (fileResp.status === 200) {
        if (fileResp.data.data && fileResp.data.data.length !== 0) {
          const listClubsFile = fileResp.data.data.filter((item) => item.clubs === 1);
          setfiles(listClubsFile);
        }



      } else {
        alert("no club found")
      }

    }
    fetchfiles();
  }, []);



  const downloadinscrip = (saison) => {
    const newTab = window.open(`/pdf/${saison}`, '_blank');
    if (newTab) {

      newTab.focus();
    } else {
      alert('منع فتح نافذة جديدة');
    }
  }

  const handleEditClick = async (id) => {
    if (club.logo instanceof File) {

      //upload image 
      const imagedataframe = new FormData();
      imagedataframe.append('image', club.logo);
      const imageName = encodeURIComponent(`/logoClub${club.numInscription}`);
      const resp = await uploadimage(imageName, imagedataframe);
      if (resp.status === 200) {
        const fileExtension = club.logo.name.split('.').pop();
        club.logo = "/logoClub" + club.numInscription + "." + fileExtension;
        const res = await updateClub(club);

        if (res.status === 201) {
          window.location.reload();

        }
      }

    } else {

      club.logo = club.pathlogo;
      delete club.pathlogo;

      const res = await updateClub(club);

      if (res.status === 201) {
        window.location.reload();

      }
    }

  }
  const changespeclialite = (LibellerARitem, checked) => {
    //alert(checked)
    if (checked) {

      club.specialite = club.specialite.split(',');
      club.specialite.push(LibellerARitem);
      club.specialite = club.specialite.join(',');
      setlistespec(club.specialite)
      //  choisi.push(LibellerARitem)


    } else {
      club.specialite = club.specialite.split(',');
      club.specialite = club.specialite.filter((sp) => sp !== LibellerARitem);
      club.specialite = club.specialite.join(',');
      setlistespec(club.specialite)


      //choisi = choisi.filter(item => item !== LibellerARitem);

    }

  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedClub = { ...club };

    if (name === 'specialite') {
      updatedClub[name] = `${updatedClub[name]}, ${value} `;
    } else if (name === 'logo') {
      const file = e.target.files[0];
      updatedClub[name] = file;
    } else {
      updatedClub[name] = value;
    }

    setclub(updatedClub);
  };
  const downloadFrais = (saison) => {
    const newTab = window.open(`/PdfFrais/${saison}`, '_blank');
    if (newTab) {

      newTab.focus();
    } else {
      alert('منع فتح نافذة جديدة');
    }
  }
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}${pdfFileName}`;

    const newTab = window.open(pdfUrl, '_blank');

    if (!newTab) {
      alert('Popup blocker prevented opening a new tab.');
    }
  };
  const renouvellement = async () => {
    const respRenouvellement = await renouvellementClub()
    if (respRenouvellement.status === 201) {
      const data = {
        sender: id,
        event: `update`,
        description: `النادي ${localStorage.getItem('clubName')} يطلب التجديد للموسم الحالي  `,

      }
      //UserSocket.current.emit("message", data);
      const resp = await setNotification(data);
      
      toast.success(respRenouvellement.message)
      window.location.reload()
    }
  }
  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

      <div>
        <div className=" row mr-2">

          <div className=" col-5 ">
            <div className="col-md-12 mb-3">
              <strong >الملفات للتنزيل</strong>
              <br />
              <a className='read_more row mt-2 mb-2' onClick={downlodcahiedecharge}   >
                {' '}<i className="fa fa-download" aria-hidden="true"></i> كراس الشروط     </a>


              {files.map((pdf) => (
                <div className="pdf-item" key={pdf.id}>
                  <p className="file-info">
                    <span className="file-name">
                      {pdf.path.split('Federation')[1].split(activesaison.libell)[0]}&nbsp;&nbsp;
                    </span>
                    <span className="upload-date">
                      تاريخ الرفع :&nbsp;&nbsp; {formatDate(pdf.date)}&nbsp;&nbsp;
                    </span>
                    {pdf.description !== "" ? (
                      <span className="description">
                        الوصف:&nbsp;&nbsp;{pdf.description}
                      </span>
                    ) : null}

                  </p>
                  <div className="buttons">
                    <button onClick={() => openPDFInNewTab(pdf.path)}>
                      معاينة
                    </button>

                  </div>
                </div>
              ))}
            </div>
            {!listClubSaison.find(item => item.saison === activesaison?.id) &&
              <div>
                <div><h2>الموسم الرياضي
                  :{activesaison?.libelle}</h2> </div>
                <button onClick={renouvellement}>تجديد التسجيل للموسم الجديد
                </button>
              </div>}
            {listClubSaison && listClubSaison.map(club => (
              <>
                <div><h2>الموسم الرياضي
                  :{listSaison.find(s => s.id === club.saison)?.libelle}</h2>{club.saison === activesaison?.id && <p style={{ color: 'red' }}>الموسم الحالي
                  </p>}</div>
                <div>  {club.valide === 1 && club.ancien === 1 ? (<div className=' float-right mb-5'>
                  <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>  لقد تم قبول مطلبك مبدئيا</span>
                </div>) :
                  club.valide === 1 && club.ancien === 0 ? (
                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>      تم استلام مطلبك يرجى مد الجامعة بالوثائق الاصلية
                    </span>

                  ) : club.valide === 2 ? (
                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>      تم قبول مطلب انخراط الجمعية في الجامعة </span>
                  ) : null}</div>

                <div className="row">
                  {club.stepInscription > 2 ? <a className='read_more row mb-2' onClick={() => { downloadinscrip(club.saison) }} >   طلب التسجيل الخاص بك {' '} <i className="fa fa-download" aria-hidden="true"></i></a> : null}
                  {club.stepInscription > 3 ? <a className='read_more row mb-2' onClick={() => downloadFrais(club.saison)} >      رسوم التسجيل  {' '}<i className="fa fa-download" aria-hidden="true"></i></a> : null}
                  <strong>خطوات التسجيل </strong>
                  <div className='steps' >
                    {club.stepInscription === 1 ? (
                      <ul>
                        <li className="step-ticket active" onClick={() => { club.renouvellement === 1 ? navigate(`/directions/${club.saison}`) : navigate('/fromulairedirection') }}> 1 .أدخل قائمة أعضاء الإدارة
                        </li>
                        <li className="step-ticket" > 2.أدخل قائمة المدربين</li>
                        <li className="step-ticket" > 3.أدخل قائمة الرياضيين </li>
                      </ul>
                    ) :
                      club.stepInscription === 2 ? (
                        <ul>
                          <li className="step-ticket" onClick={() => { navigate(`/directions/${club.saison}`) }}> 1 .أدخل قائمة أعضاء الإدارة</li>
                          <li className="step-ticket active" onClick={() => { club.renouvellement === 1 ? navigate(`/entraineurs/${club.saison}`) : navigate('/FormulaireEntraineur') }}> 2.أدخل  قائمة المدربين</li>
                          <li className="step-ticket"  >3.أدخل قائمة الرياضيين </li>
                        </ul>
                      ) :
                        club.stepInscription > 2 ? (
                          <ul>
                            <li className="step-ticket active" onClick={() => { navigate(`/directions/${club.saison}`) }}> 1 .أدخل قائمة أعضاء الإدارة</li>
                            <li className="step-ticket active" onClick={() => { navigate(`/entraineurs/${club.saison}`) }}> 2.أدخل  قائمة المدربين</li>
                            <li className="step-ticket active" onClick={() => { navigate(`/athelete/${club.saison}`) }} >3.أدخل قائمة الرياضيين </li>
                          </ul>) :
                          null
                    }
                  </div>



                </div>

              </>
            ))}





            {/**import donnee */}
            <div className=' mt-4'>


              {club.stepInscription > 1 && club.valide < 2 ? (
                <>

                  <strong>الملفات للتحميل</strong>
                  {/** demande d'inscription */}
                  <div className='row mb-2'>
                    {verif[0] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> مطلب إنخراط  </strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"Demandeinscrip"} nomfichier={id + "Demandeinscrip"} type={"مطلب_إنخراط"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[0]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>
                        <div className="col-4">
                          مطلب إنخراط
                        </div>

                        <Uploadfichier idinput={"Demandeinscrip"} nomfichier={id + "Demandeinscrip"} type={"مطلب_إنخراط"} id={id} isUpdatingfile={true} oldpath={verif[0]} />
                      </div>

                    )}
                  </div>

                  {/**2. */}
                  <div className='row mb-2'>
                    {verif[1] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> نسخة قانونية محينة من القانون الأساسي للجمعية الرياضة أو النادي الرياضي ومصادق عليه من قبل سلطة الاشراف   </strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"القانون_الأساسي_للجمعية"} nomfichier={id + "القانون_الأساسي_للجمعية"} type={"القانون_الأساسي_للجمعية"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[1]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="  col-11">
                          <strong> نسخة قانونية محينة من القانون الأساسي للجمعية الرياضة أو النادي الرياضي ومصادق عليه من قبل سلطة الاشراف   </strong>
                        </div>

                        <Uploadfichier idinput={"القانون_الأساسي_للجمعية"} nomfichier={id + "القانون_الأساسي_للجمعية"} type={"القانون_الأساسي_للجمعية"} id={id} isUpdatingfile={true} oldpath={verif[1]} />
                      </div>

                    )}
                  </div>

                  {/**3. */}
                  <div className='row mb-2'>
                    {verif[2] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> نسخة ضوئية من الإشهار بالرائد الرسمي للجمهورية التونسية  </strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"سخة_الإشهار_بالرائد"} nomfichier={id + "سخة_الإشهار_بالرائد"} type={"سخة_الإشهار_بالرائد"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[2]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className=" row col-11">
                          <strong> نسخة ضوئية من الإشهار بالرائد الرسمي للجمهورية التونسية  </strong>
                        </div>

                        <Uploadfichier idinput={"fichier3"} nomfichier={id + "fichier3"} type={"fichier3"} id={id} isUpdatingfile={true} oldpath={verif[2]} />
                      </div>

                    )}
                  </div>

                  {/**4. */}
                  <div className='row mb-2'>
                    {verif[3] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong>  نسخة قانونية من عقد التأمين للجمعية الرياضة أو النادي الرياضي </strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"نسخة_قانونية_عقد_التأمين"} nomfichier={id + "نسخة_قانونية_عقد_التأمين"} type={"نسخة_قانونية_عقد_التأمين"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[3]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className=" row col-11">
                          <strong>  نسخة قانونية من عقد التأمين للجمعية الرياضة أو النادي الرياضي </strong>
                        </div>

                        <Uploadfichier idinput={"نسخة_قانونية_عقد_التأمين"} nomfichier={id + "نسخة_قانونية_عقد_التأمين"} type={"نسخة_قانونية_عقد_التأمين"} id={id} isUpdatingfile={true} oldpath={verif[3]} />
                      </div>

                    )}
                  </div>

                  {/**5. */}
                  <div className='row mb-2'>
                    {verif[4] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong>  نسخة من التقريرين الأدبي والمالي ومحضر جلسة لآخر جلسة عامة مصادق عليها من قبل المندوييةالجهوية للرياضة المختصة ترابيا</strong>

                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"نسخة_من_التقريرين_الأدبي_والمالي"} nomfichier={id + "نسخة_من_التقريرين_الأدبي_والمالي"} type={"نسخة_من_التقريرين_الأدبي_والمالي"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[4]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong>  نسخة من التقريرين الأدبي والمالي و محضر جلسة لآخر جلسة عامة مصادق عليها من قبل المندوييةالجهوية للرياضة المختصة ترابيا</strong>
                        </div>

                        <Uploadfichier idinput={"نسخة_من_التقريرين_الأدبي_والمالي"} nomfichier={id + "نسخة_من_التقريرين_الأدبي_والمالي"} type={"نسخة_من_التقريرين_الأدبي_والمالي"} id={id} isUpdatingfile={true} oldpath={verif[4]} />
                      </div>

                    )}
                  </div>

                  {/**8. */}
                  <div className='row mb-2'>
                    {verif[5] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> قائمة في تركيبة الهيئة المديرة للجمعية الرياضية أو النادي الرياضي تتضمن وجوبا ختم رئيس الجمعية أوالنادي الرياضي وختم المندويية الجهوية المختصة ترابيا.  </strong>

                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"قائمة_تركيبة_الهيئة_المديرة"} nomfichier={id + "قائمة_تركيبة_الهيئة_المديرة"} type={"قائمة_تركيبة_الهيئة_المديرة"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[5]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong> قائمة في تركيبة الهيتة المديرة للجمعية الرياضية أو النادي الرياضي تتضمن وجوبا ختم رئيس الجمعية أوالنادي الرياضي وختم المندويية الجهوية المختصة ترابيا.  </strong>
                        </div>

                        <Uploadfichier idinput={"قائمة_تركيبة_الهيئة_المديرة"} nomfichier={id + "قائمة_تركيبة_الهيئة_المديرة"} type={"قائمة_تركيبة_الهيئة_المديرة"} id={id} isUpdatingfile={true} oldpath={verif[5]} />
                      </div>

                    )}
                  </div>

                  {/**9. */}
                  <div className='row mb-2'>
                    {verif[6] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> إستمارة الإشتراك مرفوقة بوصل يفيد إيداع معاليم الانخراط المستوجبة بالحساب الجاري للجامعة</strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"إستمارة_الإشتراك"} nomfichier={id + "إستمارة_الإشتراك"} type={"إستمارة_الإشتراك"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[6]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong> إستمارة الإشتراك مرفوقة بوصل يفيد إيداع معاليم الانخراط المستوجبة بالحساب الجاري للجامعة</strong>
                        </div>

                        <Uploadfichier idinput={"إستمارة_الإشتراك"} nomfichier={id + "إستمارة_الإشتراك"} type={"إستمارة_الإشتراك"} id={id} isUpdatingfile={true} oldpath={verif[6]} />
                      </div>

                    )}
                  </div>

                  {/**11. */}
                  <div className='row mb-2'>
                    {verif[7] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong>جدول أوقات التمارين ممضى من رئيس النادي الرياضي أو الجمعية الرياضية والممرن</strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"جدول_أوقات_التمارين"} nomfichier={id + "جدول_أوقات_التمارين"} type={"جدول_أوقات_التمارين"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[7]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong>جدول أوقات التمارين ممضى من رئيس النادي الرياضي أو الجمعية الرياضية والممرن</strong>
                        </div>

                        <Uploadfichier idinput={"جدول_أوقات_التمارين"} nomfichier={id + "جدول_أوقات_التمارين"} type={"جدول_أوقات_التمارين"} id={id} isUpdatingfile={true} oldpath={verif[7]} />
                      </div>

                    )}
                  </div>

                  {/**12. */}
                  <div className='row mb-2'>
                    {verif[8] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong> ترخيص في إستغلال قاعة أو فضاء تمارين يستجيب للشروط الخاصة برياضة الجمباز</strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"ترخيص_في_إستغلال_قاعة"} nomfichier={id + "ترخيص_في_إستغلال_قاعة"} type={"ترخيص_في_إستغلال_قاعة"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[8]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong> ترخيص في إستغلال قاعة أو فضاء تمارين يستجيب للشروط الخاصة برياضة الجمباز</strong>
                        </div>

                        <Uploadfichier idinput={"ترخيص_في_إستغلال_قاعة"} nomfichier={id + "ترخيص_في_إستغلال_قاعة"} type={"ترخيص_في_إستغلال_قاعة"} id={id} isUpdatingfile={true} oldpath={verif[8]} />
                      </div>

                    )}
                  </div>


                  {/**13. */}
                  <div className='row mb-2'>
                    {verif[9] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong>  قائمة مفصلة في التجهيزات الرياضية للجمعيات الرياضية المتعلقة برياضة الجمباز</strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"قائمة_مفصلة_في_التجهيزات"} nomfichier={id + "قائمة_مفصلة_في_التجهيزات"} type={"قائمة_مفصلة_في_التجهيزات"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[9]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong>  قائمة مفصلة في التجهيزات الرياضية للجمعيات الرياضية المتعلقة برياضة الجمباز</strong>
                        </div>

                        <Uploadfichier idinput={"قائمة_مفصلة_في_التجهيزات"} nomfichier={id + "قائمة_مفصلة_في_التجهيزات"} type={"قائمة_مفصلة_في_التجهيزات"} id={id} isUpdatingfile={true} oldpath={verif[9]} />
                      </div>

                    )}
                  </div>

                  {/**14. */}
                  <div className='row mb-2'>
                    {verif[10] === null ? (
                      <div className='row '>

                        <div className=" row col-12">
                          <strong>    كراس شروط ممضى ومختوم من رئيس الجمعية أو من ينوبه</strong>
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <Uploadfichier idinput={"كراس_الشروط"} nomfichier={id + "كراس_الشروط"} type={"كراس_الشروط"} id={id} isUpdatingfile={false} />
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className='row'>
                        <div className='col-1' onClick={() => { openPDFInNewTab(verif[10]) }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                        <div className="col-11">
                          <strong>    كراس شروط ممضى ومختوم من رئيس الجمعية أو من ينوبه</strong>
                        </div>

                        <Uploadfichier idinput={"كراس_الشروط"} nomfichier={id + "كراس_الشروط"} type={"كراس_الشروط"} id={id} isUpdatingfile={true} oldpath={verif[10]} />
                      </div>

                    )}
                  </div>
                  <button className='row float-right' onClick={() => { setpopupvalide(true) }}>  إنهاء عملية التسجيل الموسم الرياضي
                    :{activesaison?.libelle}</button>

                </>


              ) : null}
            </div>

          </div>
          {club ? (
            <div className="col-5 mr-5">

              <div className="club-form">

                <p style={{ "color": 'red', "fontSize": '25px' }}>معلوماتك</p>
                <form>

                  {isEditing ? (
                    <div >
                      <label>الشعار</label>
                      <input
                        type="file"
                        id="logo"
                        name="logo"
                        accept="image/*"
                        onChange={(e) => handleChange(e, club.logo)}
                      />
                    </div>
                  ) : <p>

                    <strong>الشعار:</strong>      <img
                      src={`data:image/png;base64,${club.logo}`}
                      alt="Club Logo"
                      width="100"
                      height="100"
                    />
                  </p>}
                  {isEditing ? (
                    <div>
                      الولاية
                      <select name="gouvernorat" value={club.gouvernorat} onChange={(e) => handleChange(e)} >
                        <option value="">Sélectionnez un gouvernorat</option>
                        {listeGouvernorat.map((gouvernorat, index) => (
                          <option key={index} value={gouvernorat}>
                            {gouvernorat}
                          </option>
                        ))}
                      </select>

                    </div>
                  ) : <p> <strong>الولاية</strong>:  &nbsp;&nbsp; &nbsp;{club.gouvernorat}</p>}

                  {isEditing ? (
                    <div>
                      إسم الجمعية أو النادي
                      <input
                        type="text"
                        name="nomClub"
                        value={club.nomClub}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />

                    </div>) : <p><strong>إسم الجمعية أو النادي</strong> :  &nbsp;&nbsp; &nbsp;{club.nomClub}</p>}
                  {isEditing ? (
                    <div>
                      العنوان
                      <input
                        type="text"
                        name="adresse"
                        value={club.adresse}

                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />
                    </div>
                  ) : <p><strong>العنوان</strong> :  &nbsp;&nbsp; &nbsp;{club.adresse}</p>}
                  {isEditing ? (
                    <div>
                      رقم التأشيرة
                      <p>  <input
                        type="text"
                        name="Numvisa"
                        value={club.Numvisa}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />
                      </p>
                    </div>
                  ) :

                    <p><strong>رقم التأشيرة</strong>  :  &nbsp;&nbsp; &nbsp;{club.Numvisa}</p>}

                  {isEditing ? (
                    <div>
                      تاريخ التأشيرة
                      <p>  <input
                        type="date"
                        name="datevisa"
                        value={club.dateVisa}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />
                      </p>
                    </div>
                  ) :

                    <p><strong>تاريخ التأشيرة</strong>  :   &nbsp;&nbsp; &nbsp;{`${new Date(club.dateVisa).getFullYear()}-${(new Date(club.dateVisa).getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}-${new Date(club.dateVisa).getDate().toString().padStart(2, '0')}`}</p>}

                  {isEditing ? (
                    <div>
                      الهاتف
                      <p>  <input
                        type="number"
                        name="NmTelephone"
                        value={club.NmTelephone}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />
                      </p>
                    </div>
                  ) :
                    <p><strong>الهاتف </strong>:   &nbsp;&nbsp; &nbsp;{club.NmTelephone}</p>}

                  {isEditing ? (
                    <div>
                      الفاكس   <input
                        type="number"
                        name="fax"
                        value={club.fax}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />

                    </div>
                  ) :
                    <p><strong>الفاكس</strong>:  &nbsp;&nbsp; &nbsp;{club.fax}</p>}
                  {isEditing ? (
                    <div>
                      البريد الإلكتروني
                      <input
                        type="text"
                        name="Email"
                        value={club.Email}
                        onChange={(e) => handleChange(e)}
                        style={{ width: '500px' }}
                      />
                    </div>
                  ) :
                    <p><strong>البريد الإلكتروني</strong>  : &nbsp;&nbsp; &nbsp; {club.Email}</p>}

                  {isEditing ? (
                    <div>
                      {specialite.map((item, index) => (
                        <div key={item}>
                          <input
                            type="checkbox"
                            id={`parcour_${item.LibellerFR}`}
                            name={`specialite[${index}]`}
                            value={item}
                            checked={listespec.includes(item.LibellerAR)}

                            onChange={(e) => { changespeclialite(item.LibellerAR, e.target.checked) }}
                          />
                          <label htmlFor={`parcour_${item.LibellerFR}`}>{item.LibellerAR} </label>
                        </div>
                      ))}
                    </div>

                  ) :
                    <p><strong>الاختصاصات</strong>: &nbsp;&nbsp; &nbsp;{club.specialite}</p>

                  }
                  {isEditing ? (
                    <div><button className="submit-btn" onClick={handleEditClick}> حفظ</button></div>) : null}



                </form>
              </div>
              {!isEditing ? (<button className="submit-btn" onClick={toggleEditing}> تحديث</button>) : null}

            </div>
          ) : <Spinner animation="grow" />}


        </div>
      </div>
      {popupvalide && (



        <div className="popup">
          <div className="popup-container">


            <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>لقد تم تسجيل طلبك بنجاح ، ملفك في طور الدراسة</span>
            {"  "}
            <button onClick={() => { setpopupvalide(false); window.location.reload(); }}>  اغلاق</button>
          </div>

        </div>
      )}
    </div>

  );
}
/*   <div className='steps' >
                {club.stepInscription === 1 ? (
                  <ul>
                    <li className="step-ticket active" onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1 </li>
                    <li className="step-ticket" >المرحلة 2 </li>
                    <li className="step-ticket" >المرحلة 3 </li>
                    <li className="step-ticket" >المرحلة 4 </li>
                  </ul>
                ) :
                  club.stepInscription === 2 ? (
                    <ul>
                      <li className="step-ticket" onClick={downloadinscrip}>المرحلة 1</li>
                      <li className="step-ticket active" onClick={() => { navigate('/formulaireathelete') }}>المرحلة 2</li>
                      <li className="step-ticket"  >المرحلة 3</li>
                      <li className="step-ticket" >المرحلة 4</li>
                    </ul>
                  ) :
                    club.stepInscription === 3 ? (
                      <ul>
                        <li className="step-ticket" onClick={downloadinscrip}>المرحلة 1</li>
                        <li className="step-ticket" onClick={downloadFrais}>المرحلة 2</li>
                        <li className="step-ticket active" onClick={() => { navigate('/PdfDirection') }}>المرحلة 3</li>
                        <li className="step-ticket" >المرحلة 4</li>

                      </ul>
                    ) :

                      <ul>
                        <li className="step-ticket" onClick={downloadinscrip}>المرحلة 1</li>
                        <li className="step-ticket" onClick={downloadFrais} >المرحلة 2</li>
                        <li className="step-ticket" onClick={downloaddirection}>المرحلة 3 </li>

                        <li className="step-ticket active" onClick={() => { navigate('/uploadfichier') }} >المرحلة 4</li>

                      </ul>

                }
              </div>*/

/***
{verif[1] ? (
                 <div className='row '>

                 <div className=" row col-4">
                 <strong> fichierlegale </strong>   <a onClick={() => { downlodfile(id + "fichierlegale") }} >   </a>
                 </div>
                 <div className='row'>
                 <div className='col-6'>                     
                  <Uploadfichier idinput={"fichierlegale"} nomfichier={id + "fichierlegale"} type={"fichierlegale"} id={id} isUpdatingfile={true} />
                 </div>
                 </div>
                 </div>

                  ) : (
                    <div className='row'>
                      <div className='col-1'  onClick={() => { downlodfile(id + "fichierlegale") }} >  <i className="fa fa-download" aria-hidden="true"></i>  </div>

                      <div className="col-4">
                      fichierlegale    
                      </div>

                      <Uploadfichier idinput={"fichierlegale"} nomfichier={id + "fichierlegale"} type={"fichierlegale"} id={id} isUpdatingfile={false} />
                      </div>
                    
                  )}

*/



/*******************  <div>
        <SubHeader/>
        <Header />
     <div>
      <p style={{'color':"red" ,'fontSize':"50px"}}>مواصلة التسجيل لهذا الموسم</p>
      <div className='steps' >
        {club.stepInscription === 1 ? (
            <div>
            <button style={{ color: 'green' }} onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1</button>
            <button disabled >المرحلة 2</button>
            <button disabled >المرحلة 3</button>
            <button disabled >المرحلة 4</button>

            </div>
         ): 
         club.stepInscription === 2 ? (
            <div>
            <button disabled >المرحلة 1</button>
            <button style={{ color: 'green' }} onClick={() => { navigate('/formulaireathelete') }}>المرحلة 2</button>
            <button disabled >المرحلة 3</button>
            <button disabled >المرحلة 4</button>
            </div>
         ): 
         club.stepInscription === 3 ? (
          <div>
          <button disabled >المرحلة 1</button>
          <button disabled >المرحلة 2</button>
          <button  style={{ color: 'green' }} onClick={() => { navigate('/PdfDirection') }}>المرحلة 3</button>
          <button disabled >المرحلة 4</button>

          </div>
       ): 
       
       <ul>

      <li> <a className="step "  onClick={() => { navigate('/uploadfichier') }} >المرحلة 4</a></li>
      <li><a className="step"  disabled >المرحلة 3</a></li>
      <li>  <a className="step"  disabled >المرحلة 2</a></li>
      <li><a className="step"  disabled onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1</a></li>

       </ul>
        
         }
</div>
     </div>
       
      </div> */

/* <div>
      <div className="containerdiv">
      <div className="right-panel">
        <p>كراس شروط المنصة الرقمية  <a   style={{ color: 'blue' }}onClick={downlodcahiedecharge} >قم بتنزيل ملف</a></p>

       <p>اكمل التسجيل</p>
       <div className='tickets-container' >
      {club.stepInscription === 1 ? (
          <div>
          <button style={{ color: 'green' }} onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1</button>
          <button disabled >المرحلة 2</button>
          <button disabled >المرحلة 3</button>
          <button disabled >المرحلة 4</button>

          </div>
       ): 
       club.stepInscription === 2 ? (
          <div>
          <button disabled >المرحلة 1</button>
          <button style={{ color: 'green' }} onClick={() => { navigate('/formulaireathelete') }}>المرحلة 2</button>
          <button disabled >المرحلة 3</button>
          <button disabled >المرحلة 4</button>
          </div>
       ): 
       club.stepInscription === 3 ? (
        <div>
        <button disabled >المرحلة 1</button>
        <button disabled >المرحلة 2</button>
        <button  style={{ color: 'green' }} onClick={() => { navigate('/PdfDirection') }}>المرحلة 3</button>
        <button disabled >المرحلة 4</button>

        </div>
     ): 
     
     <ul>

    <li> <a className="step-ticket active"  onClick={() => { navigate('/uploadfichier') }} >المرحلة 4</a></li>
    <li><a className="step-ticket"  disabled >المرحلة 3</a></li>
    <li>  <a className="step-ticket"  disabled >المرحلة 2</a></li>
    <li><button className="step-ticket"  disabled onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1</button></li>

     </ul>
      
       }
</div>
       <ul>
        <li>
        <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate('/directions')}}><p><strong >قائمة  المسيرين     </strong></p></a>

        </li>
        <li>
        <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate('/entraineurs')}}><p><strong >قائمة  المدربين     </strong></p></a>

        </li>
        <li>
        <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate('/athelete')}}><p><strong >قائمة  الرياضيين     </strong></p></a>

        </li>
       </ul>

       
      </div>
      <hr className="divider" />
      <div className="left-panel">
       <p>معلوماتك</p>
       <div>
          <p> <strong>الولاية</strong>:  &nbsp;&nbsp; &nbsp;{club.gouvernorat}</p>
          <p><strong>إسم الجمعية أو النادي</strong> :  &nbsp;&nbsp; &nbsp;{club.nomClub}</p>
          <p><strong>العنوان</strong> :  &nbsp;&nbsp; &nbsp;{club.adresse}</p>
          <p><strong>رقم التأشيرة</strong>  :  &nbsp;&nbsp; &nbsp;{club.Numvisa}</p>
          <p><strong>تاريخ التأشيرة</strong>  :   &nbsp;&nbsp; &nbsp;{`${new Date(club.dateVisa).getFullYear()}-${(new Date(club.dateVisa).getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${new Date(club.dateVisa).getDate().toString().padStart(2, '0')}`}</p>
          <p><strong>الهاتف </strong>:   &nbsp;&nbsp; &nbsp;{club.NmTelephone}</p>
          <p><strong>الفاكس</strong>:  &nbsp;&nbsp; &nbsp;{club.fax}</p>
          <p><strong>البريد الإلكتروني</strong>  : &nbsp;&nbsp; &nbsp; {club.Email}</p>
          <p><strong>الاختصاصات</strong>: &nbsp;&nbsp; &nbsp;{club.specialite}</p>
          </div>
      </div>
     
    </div>
      </div>*/
/*  <li> <button className="step active"  onClick={() => { navigate('/uploadfichier') }} >المرحلة 4</button></li>
<li><button className="step"  disabled >المرحلة 3</button></li>
<li>  <button className="step"  disabled >المرحلة 2</button></li>
<li><button className="step"  disabled onClick={() => { navigate('/fromulairedirection') }}>المرحلة 1</button></li>*/
/* const downloaddirection = () => {
    const newTab = window.open(`/PdfDirection`, '_blank');
    if (newTab) {

      newTab.focus();
    } else {
      alert('منع فتح نافذة جديدة');
    }
  }


  const downloadFrais = () => {
    const newTab = window.open(`/PdfFrais`, '_blank');
    if (newTab) {

      newTab.focus();
    } else {
      alert('منع فتح نافذة جديدة');
    }
  }*/