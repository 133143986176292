import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { uploadfederation } from '../../Service/ImportPdfs/ImportPdfsApi';
import ClubsTables from '../Clubs/ClubsTables';

import FedrationFile from '../FedrationFile/FedrationFile';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModifMDP from '../ModifMDP/ModifMDP';
import Publication from '../Publication/Publication';

export default function SecretariatGeneral() {
  const navigate = useNavigate();
  const listSaison = JSON.parse(localStorage.getItem('listsaison'))
  const [saison, setSaison] = useState()
  const activesaison = JSON.parse(localStorage.getItem('saison_active'))
  const saisonprecedent = JSON.parse(localStorage.getItem('saison_precedente'))

  const [filename, setfilname] = useState(null);
  const [descriptions, setdescriptions] = useState(null);
  const [ClubsChecked, setClubsChecked] = useState(false);
  const [AcceuilChecked, setAcceuilChecked] = useState(false);
  const [ShowModelmodifiermotdepasse, setShowModelmodifiermotdepasse] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleEditClick = async () => {


  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const inputFilename = '_Federation' + filename + localStorage.getItem('saison'); // Get the filename from the input field

    if (selectedFile && inputFilename) {
      const formData = new FormData();
      formData.append('pdf', selectedFile);

      // Encode the inputFilename if needed
      const nomfichier = encodeURIComponent(inputFilename);
      const Descriptions = encodeURIComponent(descriptions);
      if (ClubsChecked) {
        axios.defaults.headers.common['x-club'] = 1;

      } if (AcceuilChecked) {
        axios.defaults.headers.common['x-acceuil'] = 1;

      }
      axios.defaults.headers.common['nomfichier'] = encodeURIComponent(filename);
      axios.defaults.headers.common['x-file-name'] = nomfichier;
      axios.defaults.headers.common['resp'] = localStorage.getItem('email');
      axios.defaults.headers.common['event'] = "upload";
      axios.defaults.headers.common['x-descriptions'] = Descriptions;

      uploadfederation(formData)
        .then((response) => {
          toast.success("تحميل الملف بنجاح");
        })
        .catch((error) => {
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
        })
        .finally(() => {
          // Remove custom headers after sending the request
          delete axios.defaults.headers.common['x-file-name'];
          delete axios.defaults.headers.common['resp'];
          delete axios.defaults.headers.common['event'];

          window.location.reload();
        });
    } else {
      toast.error("   أدخل أولاً اسم الملف");
    }
  };

  useEffect(() => {
    if (activesaison && activesaison.id) {
      setSaison(activesaison.id)

    } else if (saisonprecedent && saisonprecedent.id) {
      setSaison(saisonprecedent.id)

    }
  }, [])



  return (


    <div className="main-container yoga-template">
      {/*Banner section*/}
      <div className="banner" style={{ backgroundImage: `url('/images/photo1.jpg'})`, maxHeight: '200px' }}>

        <form >

          <p><strong>إسم   </strong> : {localStorage.getItem('nom')}</p>
          <p><strong>اللقب</strong> :  {localStorage.getItem('prenom')}</p>
          <p><a className='submit-btn ' onClick={() => setShowModelmodifiermotdepasse(true)}>تعديل كلمة المرور</a></p>

          <ModifMDP showModel={ShowModelmodifiermotdepasse} setShowModel={setShowModelmodifiermotdepasse} email={localStorage.getItem('email')} />
          <button className='submit-btn' onClick={() => navigate('/home/inscription')}>اضافة جمعية </button>


        </form>


      </div>
      <div className="yogaPlace-section">
        

        <ClubsTables saison={saison} setSaison={setSaison} listSaison={listSaison}/>
      </div>
      <div className="best-class-club mb-3 ml-4 " >
        <div className="row best-class-sections-detail">
          <div className="col-lg-8 best-class-club-desc">
            <div >
              < FedrationFile />


            </div>
          </div>
          <div className="col-lg-4 best-class-club-img" >
            <div >

              <div className='mt-5'>
                <input type='text' placeholder=" اسم الملف" onChange={(e) => { setfilname(e.target.value) }} />

                <input type='text' placeholder="تعليق" onChange={(e) => { setdescriptions(e.target.value) }} />
              </div>
              <div>
                <label>
                  <input type='checkbox' value='acceuil' onChange={(e) => { setAcceuilChecked(e.target.checked) }} />
                  الصفحة الرئيسية
                </label>
              </div>
              <div>
                <label>
                  <input type='checkbox' value='clubs' onChange={(e) => { setClubsChecked(e.target.checked) }} />
                  الأندية
                </label>
              </div>

              <label for={filename} class="custom-file-input">
                <span class="folder-icon">&#128193;</span>
              </label>
              <input type="file" id={filename} accept=".pdf" onChange={handleFileChange} style={{ "display": "none" }} />


            </div>
          </div>
        </div>
      </div>
      <Publication />



    </div>
  )
}
/*          {!isEditing ? (<button className="submit-btn" onClick={toggleEditing}> تحديث</button>) : null}
*/  /*const handleChange = (e) => {

    const { name, value } = e.target;
    const updatedResp = { ...resp };
    if (name === 'logo') {
      const file = e.target.files[0];
      updatedResp[name] = file
    } else {
      updatedResp[name] = value;
    }
    setresp(updatedResp);

  };*/