import React, { useState, useEffect } from 'react'
import { getAllAthelets } from '../../Service/Athlete/AthleteApi'
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { getallclubs } from '../../Service/Federation/FederationApi'
import Athletefiche from '../Athletefiche/Athletefiche';
import { useParams ,useNavigate} from 'react-router-dom';

export default function AllAtheletes() {
  const [Athelets, setAthelets] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClub, setSelectedClub] = useState(''); // State for selected club
  const [clubs, setClubs] = useState([]);
  const [athlete, setathlete] = useState()
  const [showModel, setshowModel] = useState(false)


  const { saison} = useParams();
  const listSaison = JSON.parse(localStorage.getItem('listsaison'))
  useEffect(() => {
    const fetchData = async () => {
      try {
        const atheletesResp = await getAllAthelets(saison);
        if (atheletesResp.status === 200) {
          const respdiscipline = await Getdiscipline();

          setDiscipline(respdiscipline.data.data);
          atheletesResp.data.data.forEach(element => {
            const specialiteListe = [];
            const categoriesAges = [];

            respdiscipline.data.data.forEach(item => {
              if (element[item.libellerFR] !== null) {
                specialiteListe.push(item['LibellerAR']);
                categoriesAges.push(element[`${[item.libellerFR]}_CA`]);
              }
            });
            element.specialites = specialiteListe.join(',');
            const categoriesAgeslist = categoriesAges.filter(item => item !== '' && item !== null);
            element.categories_age = categoriesAgeslist.join(',');
          });

          setAthelets(atheletesResp.data.data);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while getting data');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const clubResp = await getallclubs(saison);
        if (clubResp.status === 200) {
          setClubs(clubResp.data.data);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while getting data');
      }
    };
    fetchClubs();
  }, []);

  const handleSpecialtyChange = (event) => {
    setSelectedSpecialty(event.target.value);
  };

  const handleClubChange = (event) => {
    setSelectedClub(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const openmodel = (item) => {
    setathlete(item)
    setshowModel(true)
  }
  const filteredAthelets = Athelets
    .filter((Athelet) => {
      if (selectedSpecialty && selectedSpecialty !== 'All') {
        return Athelet.specialites.includes(selectedSpecialty);
      }
      return true;
    })
    .filter((Athelet) => {
      const search = searchTerm.toLowerCase();
      return (
        Athelet.nom.toLowerCase().includes(search) ||
        Athelet.prenom.toLowerCase().includes(search)
      );
    })
    .filter((Athelet) => {
      if (selectedClub && selectedClub !== 'All') {
        return Athelet.nomClub === selectedClub;
      }
      return true;
    });

  return (
    <div>
                <h2><strong> الموسم الرياضي {listSaison?.find(item => parseInt(item.id) === parseInt(saison))?.libelle}</strong></h2>

      <div className='row mt-3 mr-2'><h2 className='col-2F'><strong>قائمة الرياضيين </strong></h2>
        <h2 className='col-2'> <strong>  العدد الجملي </strong> {filteredAthelets.length}</h2>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>

        <select
          style={{ 'width': '600px' }}
          id="specialtyFilter"
          onChange={handleSpecialtyChange}
          value={selectedSpecialty}
        >
          <option value="">تصفية حسب الاختصاصات</option>
          {discipline.map((item) => (
            <option key={item.id} value={item.LibellerAR}>
              {item.LibellerAR}
            </option>
          ))}
        </select>

        {/* Club filter dropdown */}
        <select
          style={{ 'width': '600px' }}
          id="clubFilter"
          onChange={handleClubChange}
          value={selectedClub}
        >
          <option value="">تصفية حسب النادي</option>
          {clubs.map((club) => (
            <option key={club.id} value={club.nomClub}>
              {club.nomClub}
            </option>
          ))}
        </select>
        <div style={{ "marginTop": "20px", 'width': '600px', 'marginLeft': '50px' }}>
          <input
            placeholder='البحث بالاسم أو اسم العائلة'
            type="text"
            id="search"

            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </div>
      </div>


      <table>
        <thead>
          <tr>
            <th></th>
            <th className='center-text'>اسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>جنسية</th>
            <th className='center-text'>اسم النادي</th>
            <th className='center-text'>الاختصاصات</th>
            <th className='center-text'>الفئات العمرية</th>
          </tr>
        </thead>
        <tbody>
          {filteredAthelets.map((item,i) => (
            <tr key={item.id} >
              <td>{i+1}</td>
              <td onClick={() => openmodel(item)}>{item.nom}</td>
              <td>{item.prenom}</td>
              <td>{item.nationalite}</td>
              <td>{item.nomClub}</td>
              <td>{item.specialites}</td>
              <td>{item.categories_age}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Athletefiche showModal={showModel} setshowModal={setshowModel} item={athlete} />
    </div>
  );
}
