import React, { useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import Pdfinscription from '../PdfInscription/Pdfinscription';
import Header from '../../shared/Header';
import FormulaireLogin from '../Login/FormulaireLogin'
import { useNavigate } from "react-router-dom";
import Footer from '../../shared/Footer';

export default function Home() {

  const navigate = useNavigate();
  const [popupinvalide, setpopupinvalide] = useState(false);

  const downloadPDF = () => {
    const element = document.getElementById('pdf-content');
  
    html2pdf()
      .set({
        margin: 1,
        filename: `مطلب إنخراط.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { useCORS: true }
      })
      .from(element)
      
      .save();
  };
  const handleTelechargerClick = () => {
    const filePath = process.env.PUBLIC_URL + 'anydesk/AnyDesk.exe'; // Chemin vers le fichier dans le dossier public

    // Crée un lien <a> avec le chemin du fichier
    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'AnyDesk.exe'; // Nom du fichier à télécharger

    // Ajoute le lien au document
    document.body.appendChild(link);

    // Déclenche un clic sur le lien pour démarrer le téléchargement
    link.click();

    // Supprime le lien du document
    document.body.removeChild(link);
  };

  return (
    <div>

      <div>
      <div className="containerdiv">
      <div className="right-panel">
        <div className='row'><p>إذا لم يكن لديك حساب، قم بالتسجيل</p>
        <button className='submit-btn' onClick={()=>navigate('/home/inscription')}>استمارة التسجيل</button>
        </div>
        <div className='row mt-5'><a className='submit-btn ' href='https://drive.google.com/file/d/1fAdbeMmatO306RhUJ5evyR2ddNjYOBjQ/view'> انقر هنا لمشاهدة فيديو توضيحي لخطوات التسجيل
</a></div>

<div className='row mt-5'><span  > للمساعدة عند التسجيل في المنصة يمكنك الاتصال بالرقم 
 648 348 22
</span><span className='submit-btn '  onClick={()=>handleTelechargerClick()} >تحميل AnyDesk
</span></div>

      </div>

  
      <hr className="divider" />
      <div className="left-panel">
       <FormulaireLogin/>
      </div>
      {popupinvalide && (



<div className="popup">
    <div className="popup-container">
    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>لقد تجاوزت أجال التسجيل</span>

          
        <button onClick={() => { setpopupinvalide(false) }}>  غلق</button>
    </div>

</div>
)}
     
    </div>
      </div>
    </div>
  );
}
