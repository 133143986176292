import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function getFederation() {

    try {
        
        const response = await axios.get(`${url}federation/getfederation`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getFrais(saisonId) {

    try {
        const response = await axios.get(`${url}federation/getfrais/${saisonId}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
export async function  getroles(){
    try {
        const response = await axios.get(`${url}federation/getRoles`)
        return response;

    }catch(error){
        return error?.response
    } 
}


export async function updatenumlicencesentraineur (data) {

    try {
        
        const response = await axios.put(`${url}federation/updatenumlicencesentraineur`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }
export async function  getnumlicencesEntraineur(){
    try {
        const response = await axios.get(`${url}federation/getnumlicencesEntraineur`)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function loginFederation (data){
    try {
        const response = await axios.post(`${url}federation/login`,data)
        return response;

    }catch(error){
        return error?.response
    } 
}

export async function getnotif() {
    try {
        const response = await axios.get(`${url}federation/getNotif`)
        return response;

    }catch(error){
        return error?.response
    } 
}


export async function getpdfs(sender) {
    try {
        const response = await axios.get(`${url}federation/getpdfs/${sender}`)
        return response;

    }catch(error){
        return error?.response
    } 
}

export async function validenotif(resp,idclub,id) {
    try {
        const response = await axios.put(`${url}federation/validenotif/${resp}/${idclub}/${id}`)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function invalidenotif(resp,idclub,id) {
    try {
        const response = await axios.put(`${url}federation/invalidenotif/${resp}/${idclub}/${id}`)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function confirmationfinale(resp,idclub) {
    try {
        const response = await axios.put(`${url}federation/confirmationfinale/${resp}/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function setseen(resp,id) {
    try {
        const response = await axios.put(`${url}federation/setseen/${resp}/${id}`)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function createuser(user) {
    try {
        const response = await axios.post(`${url}federation/CreatUser`,user)
        return response;

    }catch(error){
        return error?.response
    } 
}
export async function setNotification(data) {

    try {
        
        const response = await axios.post(`${url}federation/setNotification`,data)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  export async function removepub(id) {

    try {
        
        const response = await axios.delete(`${url}federation/removepub/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }
  export async function updatepassword(data) {

    try {
        
        const response = await axios.put(`${url}federation/updatepassword`,data)
        return response;

    }catch(error){
        return error?.response
    }
  } 
export async function createrole(role) {
    try {
        const response = await axios.post(`${url}federation/createrole`,role)
        return response;

    }catch(error){
        return error?.response
    } 
}

export async function getallclubs(saison) {
    try {
        const response = await axios.get(`${url}federation/getallclubs/${saison}`)
        return response;

    }catch(error){
        return error?.response
    } 
}

export async function getAllfiles() {
    try {
        const response = await axios.get(`${url}federation/getallfiles`)
        return response;

    }catch(error){
        return error?.response
    } 
}

