import React, { useEffect, useState } from 'react'
import Header from '../../shared/Header';
import html2pdf from 'html2pdf.js';
import { getcount, getclub,updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
import { getFederation } from '../../Service/Federation/FederationApi';

import { getdirection } from '../../Service/Directionclub/DirectionclubApi';
import PdfHeader from '../../shared/PdfHeader';
import PdfFooter from '../../shared/PdfFooter';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function PdfDirection() {
    const [club , setclub]=useState([]);
    const [direction , setdirections]=useState([]); 
    const [directionData,setdirectionData]=useState([]); 
    const [federation , setfederation]=useState([]); 
    const activesaison=JSON.parse(localStorage.getItem('saison_active'))

    useEffect(() => {
      const fetchfederation = async()=>{
       const federationResp = await getFederation();
       if(federationResp.status === 200){
          setfederation(federationResp.data.data); 
        localStorage.setItem('saison',federationResp.data.data.saison)
       }else{
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")
      }
       
       }  
       fetchfederation();  
       updatestep();

     }, []);
    useEffect(() => {
        const fetchClubs = async()=>{
        const clubResp = await getclub(activesaison?.id);
        if(clubResp.status === 200){
            setclub(clubResp.data.data[0]); 
           
        }else{
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
        }
        
        }  
        fetchClubs();  
    }, []);
    useEffect(() => {
        const fetchDirection = async () => {
          try {
            const diretionResp = await getdirection();
            if (diretionResp.status === 200) {
                setdirectionData(diretionResp.data.data);
                setdirections(diretionResp.data.data[0]) ;
            } 
          } catch (error) {
            toast.error("نحن آسفون، يبدو أن هناك مشكلة")
          }
        };
        
        fetchDirection();
      }, []);
      const updatestep = async()=>{
        const numInscription=localStorage.getItem('idunique')
        const  saison = activesaison.id
       const data = { step: 4 ,numInscription:numInscription, saison:saison};
      
        if(club.stepInscription < data.step){
          
      
      const clubResp = await updatesteps(data);
      if(clubResp.status === 200){

      }
    }
      }  
   const download = async () => {
        // Loop through the direction data
        for (let i = 0; i < directionData.length; i++) {
          const currentDirection = directionData[i];
          
          // Clone the template for each direction
          const template = document.createElement('div');
          template.innerHTML = ` 
          <div>
         
      <div  class="pdf-content2" id="pdf-content">
      
      <div className='subheader'>
    <p className='arabic-text'>
      <strong >بطاقة إرشادات مسير    </strong>
    </p>
    <p  className='arabic-text'>
      <strong>الموسم الرياضي ${localStorage.getItem('saison')}</strong>
    </p>

    <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />

</div>
    <div class="container-head">
 
      <img
      src=${`data:image/png;base64,${direction.image}`}
      alt="Club Logo"
      width="150px"
      height="1550px"
    /></div>


      <div>
      
      
      <br/>
      
      <p> إسم الجمعية    : &nbsp;&nbsp;&nbsp;&nbsp; ${club.nomClub}</p>
      <p> إسم واللقب   : &nbsp;&nbsp;&nbsp;&nbsp;${direction.nom} &nbsp;&nbsp;&nbsp;&nbsp;${direction.prenom}</p>
      
      <div  class="memeligne">
        <p class="p1">تاريخ و مكان الولادة :   ${new Date(direction.datenaissance).getFullYear()}-${(new Date(direction.datenaissance).getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${new Date(direction.datenaissance).getDate().toString().padStart(2, '0')}   &nbsp;&nbsp;&nbsp;&nbsp;${direction.lieunaissance}</p>
        <p class="p2">
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        الجنسية: &nbsp;&nbsp;&nbsp;&nbsp;${direction.nationalite}
      </p> 
      </div>  
  
      <div  class="memeligne">
      <p class="p1"> رقم بطاقة التعريف الوطنية:    ${direction.lieunaissance}</p>
      <p class="p2">
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
      تاريخ الاصدار: &nbsp;&nbsp;&nbsp;&nbsp;$${new Date(direction.datenaissance).getFullYear()}-${(new Date(direction.datenaissance).getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${new Date(direction.datenaissance).getDate().toString().padStart(2, '0')} 
    </p> 
    </div> 
      <p>العنوان :&nbsp;&nbsp;&nbsp;&nbsp; ${direction.adresse}</p>
      <p style={{ margin: '0' }}>الهاتف : &nbsp;&nbsp;&nbsp;&nbsp;${direction.numTelephone}</p>
      <p>البريد الإلكتروني : &nbsp;&nbsp;&nbsp;&nbsp;${direction.email}</p>
      <p>الصفة بالجمعية   : &nbsp;&nbsp;&nbsp;&nbsp;${direction.role}</p>
      </div> 
      
      <br/> <br/>
      <header>
          <div class="info-container">
            <div class="arabic-infobuttom">
              <p>التاريخ     </p>
            </div>
            <div class="image-container">
              <p>ختم الجمعية </p>
            </div>
            <div class="french-infobuttom">
              <p>امضاء رئيس او اكتب عام الجمعية</p>
            </div>
          </div>
        </header>
       </div>
       </div>
          `;
          
          // Populate the cloned template with data for the current direction
          const pdfContent = template.querySelector('#pdf-content');
          template.innerHTML = ` 
          <div>
         
      <div  class="pdf-content2" id="pdf-content">
      
      <div class='subheader'>
      <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />

    <p class='arabic-text'>
      <strong >بطاقة إرشادات مسير    </strong>
    </p>
    <p  class='arabic-text'>
      <strong>الموسم الرياضي ${localStorage.getItem('saison')}</strong>
    </p>


     </div>
    <div class="container-head">
 
      <img
      src=${`data:image/png;base64,${direction.image}`}
      alt="Club Logo"
      width="150px"
      height="1550px"
    /></div>


      <div>
      
      
      <br/>
      
      <p> إسم الجمعية    : &nbsp;&nbsp;&nbsp;&nbsp; ${club.nomClub}</p>
      <p> إسم واللقب   : &nbsp;&nbsp;&nbsp;&nbsp;${direction.nom} &nbsp;&nbsp;&nbsp;&nbsp;${direction.prenom}</p>
     
      <div  class="memeligne">
        <p class="p1">تاريخ و مكان الولادة :   ${new Date(direction.datenaissance).getFullYear()}-${(new Date(direction.datenaissance).getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${new Date(direction.datenaissance).getDate().toString().padStart(2, '0')}   &nbsp;&nbsp;&nbsp;&nbsp;${direction.lieunaissance}</p>
        <p class="p2">
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        الجنسية: &nbsp;&nbsp;&nbsp;&nbsp;${direction.nationalite}
      </p> 
      </div>   <br/><br/>
  
      <div  class="memeligne">
      <p class="p1"> رقم بطاقة التعريف الوطنية:    ${direction.lieunaissance}</p>
      <p class="p2">
      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
      تاريخ الاصدار: &nbsp;&nbsp;&nbsp;&nbsp;$${new Date(direction.datenaissance).getFullYear()}-${(new Date(direction.datenaissance).getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${new Date(direction.datenaissance).getDate().toString().padStart(2, '0')} 
    </p> 
    </div> 
      <p>العنوان :&nbsp;&nbsp;&nbsp;&nbsp; ${direction.adresse}</p>
      <p style={{ margin: '0' }}>الهاتف : &nbsp;&nbsp;&nbsp;&nbsp;${direction.numTelephone}</p>
      <p>البريد الإلكتروني : &nbsp;&nbsp;&nbsp;&nbsp;${direction.email}</p>
      <p>الصفة بالجمعية   : &nbsp;&nbsp;&nbsp;&nbsp;${direction.role}</p>
      </div> 
      
      <br/> <br/>
      <header>
          <div class="info-container">
            <div class="arabic-infobuttom">
              <p>التاريخ     </p>
            </div>
            <div class="image-container">
              <p>ختم الجمعية </p>
            </div>
            <div class="french-infobuttom">
              <p>امضاء رئيس او اكتب عام الجمعية</p>
            </div>
          </div>
        </header>
        <footer  class="footerStyle">

    <p>&#127968; ${federation.adresseAr}</p>
    <p>   &#9742;الهاتف / الفاكس:  ${federation.telfax}</p>
    <p>&#9993; البريدإلكتروني: ${federation.email}</p>


</footer>
       </div>
       </div>
          `;
          
          // Generate PDF from the cloned and populated content
          await html2pdf()
            .set({
              margin: 1,
              filename: ` بطاقة إرشادات مسير_${currentDirection.nom}_${currentDirection.prenom}.pdf`,
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { useCORS: true },
            })
            .from(template)
            .save();
          
          // Update the step if needed
          updatestep();
        }
      };
      
      
  return (
    <div>
  <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

    <button onClick={download}>تحميل</button>
<div  className="pdf-content2" id="pdf-content">
<PdfHeader nom=" بطاقة إرشادات مسير  " />
    <div className="container-head">
      <div className="left-div">
        <img
        src={`data:image/png;base64,${direction.image}`}
        alt="Club Logo"
        width="250px"
        height="250px"
      /></div>

    </div>

<div>


<br/>

<p> إسم الجمعية    : {' '}{club.nomClub}</p>
<p> إسم واللقب   : {' '}{direction.nom}&nbsp;&nbsp;&nbsp;&nbsp; {direction.prenom}</p>

<div  style={{ display: 'flex', alignItems: 'center' }}>
  <p style={{ margin: '0' }}>تاريخ و مكان الولادة : {' '}  {`${new Date(direction.datenaissance).getFullYear()}-${(new Date(direction.datenaissance).getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${new Date(direction.datenaissance).getDate().toString().padStart(2, '0')}`} &nbsp;&nbsp;&nbsp;&nbsp;  {direction.lieunaissance}</p>
  <p style={{ margin: '0px 20px 0px 10px' }}>
  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
  الجنسية: {' '}{direction.nationalite}
</p> 
<br/><br/><br/>
</div>  
<div  style={{ display: 'flex', alignItems: 'center' }}>
  <p style={{ margin: '0' }}> رقم بطاقة التعريف الوطنية  : {' '}    {' '} {direction.cin}</p>
  <p style={{ margin: '0px 20px 0px 10px' }}>
  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
  تاريخ الاصدار: {' '}{`${new Date(direction.datecin).getFullYear()}-${(new Date(direction.datecin).getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${new Date(direction.datecin).getDate().toString().padStart(2, '0')}`}
</p> 
</div> 

<p>العنوان :{' '} {direction.adresse}</p>
<p style={{ margin: '0' }}>الهاتف : {' '}{direction.numTelephone}</p>
<p>البريد الإلكتروني : {' '}{direction.email}</p>
<p>الصفة بالجمعية   : {' '}{direction.role}</p>
</div> 

<br/> <br/>
<header>
    <div className="info-container">
      <div className="arabic-infobuttom">
        <p>التاريخ     </p>
      </div>
      <div className="image-container">
        <p>ختم الجمعية </p>
      </div>
      <div className="french-infobuttom">
        <p>امضاء رئيس او اكتب عام الجمعية</p>
      </div>
    </div>
  </header>
  <PdfFooter />
 </div>
 </div>
  )
}
