import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'; 
import { getnotif  ,invalidenotif,setseen,validenotif } from '../Service/Federation/FederationApi';
import io from "socket.io-client";

export default function SubHeaderFederation() {

 
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifs ,setnotifs]=useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const activesaison = JSON.parse(localStorage.getItem('saison_active'))

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };
    const currentPath = window.location.pathname; // Get the current path
    const responsible =localStorage.getItem('email');

    const navigate = useNavigate(); // Get the navigate function
    useEffect(() => {
      const fetchNotif = async () => {
        try {
          const resp = await getnotif();
    
          if (resp.status === 200) {
            const nonSeen = resp.data.data.filter((element) => element.seen === 0);
            
            setUnreadCount(nonSeen.length);
            const sortedNonSeen = nonSeen.sort((a, b) => {
              const dateA = new Date(a.date).getTime();
              const dateB = new Date(b.date).getTime();
              return dateB - dateA; // Compare in reverse order for descending sorting
            });
            
            setnotifs(sortedNonSeen);
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      };
    
      fetchNotif();
   
     /* const adminSocket = io('https://api.ftgym.tn');
      adminSocket.emit("message", "i am admin");
    
      adminSocket.on("messageFromUser", (data) => {
        // Check if the notification is already in notifs
        console.log(data)
        const isDuplicate = notifs.some(
          (item) =>
            item.date === data.date &&
            item.sender === data.sender &&
            item.event === data.event
        );
    
        if (!isDuplicate) {
    
          setnotifs((prevList) => [...prevList, data]);
          setUnreadCount((prevCount) => prevCount + 1);
        }
      });*/
    
      // Clean up the socket connection on unmount if necessary

    }, []);  
    

  
    const logout =()=>{
        localStorage.removeItem('IDrole');
        localStorage.removeItem('email');
        localStorage.removeItem('saison');

        navigate('/home/loginadmin');
        window.location.reload();
  
  
      }
      const markAsSeen = async (id) => {
        try {
          const resp = await setseen(responsible,id);
    
          if (resp.status === 200) {
           window.location.reload();
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      };
    
      const toggleUserInfo = () => {
        setShowUserInfo(!showUserInfo);
      };

      const handelgetpdfs = async (idclub) => {
        const newTab = window.open(`${process.env.REACT_APP_API_URL}federation/getpdfs/${activesaison?.id}/${idclub}`, '_blank');
        if (newTab) {
          // If the new tab was successfully opened, you can focus on it (optional)
          newTab.focus();
        } else {
          alert('Popup blocker prevented opening a new tab.');
        }
      };
      const valide= async(id,sender)=>{
        try {
          const resp = await validenotif(responsible,sender,id);
    
          if (resp.status === 200) {
           window.location.reload();
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      }

      const invalide= async(id,sender)=>{
        try {
          const resp = await invalidenotif(responsible,sender,id);
    
          if (resp.status === 200) {
           window.location.reload();
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      }
      
      const toggleUser = () => {
        setShowUser(!showUser);
      };
    const goBack = () => {
      navigate(-1); // Use navigate with -1 to go back one step
  };
  return (
   <div> 
<div className='subheader'>
  
  {localStorage.getItem("IDrole") ? (
    <ul>

    <li> <button  className="home" onClick={logout}>
        &#128274; تسجيل خروج {/* Unicode for a logout icon */}
      </button></li> 
       { !currentPath.startsWith("/home/SecretariatGeneral") ? (
       <>
       <li>
        <button className="home" onClick={() => goBack()}>
                ⬅️  </button>
        </li>
        <li> <button className="home" onClick={() => navigate("/home/SecretariatGeneral")}>
                  &#128100; {/* Unicode for a home icon */}
                </button></li>
            </>
      ) : null}
       <li> 
        <button  className="home"  onClick={toggleNotifications}>
            &#128276;     {/* Unicode for a home icon */}
            {unreadCount > 0 && (
          <span className="notification-badge">{unreadCount}</span>
        )}
   
        </button>

   </li>

        

  
    </ul>
    
  ) : null}


</div>
{showNotifications && (
  <div className="notifications-popup">
    <h2>Notifications</h2>
    <ul>
      {notifs.map((notif, index) => (
        <li key={index}>
          <div className="notification-item">
            <div className="notification-header">
              <span className={notif.seen !== 0 ? 'notification-status unread' : 'notification-status read'}>
              <button onClick={() => { markAsSeen(notif.id) }}>X</button>

              </span>
              <span className="notification-date">
                ({new Date(notif.date).toLocaleDateString()})
              </span>
            </div>
            
            <p className="notification-description">
              {notif.description}
              <a onClick={() => { handelgetpdfs(notif.sender) }} style={{ "color": "blue" }}>انظر الوثيقة</a>
            </p>
            <div className="notification-actions">
              <button onClick={() => { valide(notif.id, notif.sender) }}>  استلام الملف</button>
            </div>
            <div className="notification-actions mt-3">
              <button onClick={() => { invalide(notif.id, notif.sender) }}>   رفض المطلب</button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
)}


<nav className="navbar">

<ul className="menu-list">
<li style={currentPath.startsWith('/home/AllClubs/')  ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate(`/home/AllClubs/${activesaison?.id}`); }}>الأندية</li>
  <li style={currentPath.startsWith('/home/AllEntraineurs') ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate(`/home/AllEntraineurs/${activesaison?.id}`); }}>المدربين</li>
  <li style={currentPath.startsWith('/home/AllAtheletes') ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate(`/home/AllAtheletes/${activesaison?.id}`); }}>الرياضيين</li>
</ul>
</nav>
</div>

    
  )
}

/*  {showUserInfo && (
    <div className="user-info">
<p>Nom de l'utilisateur : John Doe</p>
<p>Email : john@example.com</p>
</div>
)}

{showUser && (
    <div className="user-info">
<p>Nom de l'utilisateur : John Doe</p>
<p>Email : john@example.com</p>
</div>
)}*/

   /* useEffect(() => {
      adminSocket.on("connect", () => {
        adminSocket.emit("message", "i am admin");
      });
      }, []);*/
/*

  adminSocket.on("messageFromUser", (data) => {
    console.log(data)
    alert(unreadCount)
    setUnreadCount(unreadCount + 1); // Incrementing the unread count
  });
  *//*<div className="news-ticker">
    <p style={{
  fontSize: '18px',
  color: 'red',
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center', 
  fontFamily: 'Arial, sans-serif'
}}>
      سلام لأرض خلقت للسلام وما رأت يوما سلاما - فلسطين حرة
      <img src="/images/flag.png" alt="Flag of Palestine" style={{ width: '50px', height: '30px', marginLeft: '10px' }} />
    </p>
  </div>*/