import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { postpdfs ,updatepdfs} from '../../Service/ImportPdfs/ImportPdfsApi';
import io from "socket.io-client";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setNotification } from '../../Service/Federation/FederationApi';
export default function Uploadfichier({ idinput,nomfichier, type,  id ,isUpdatingfile ,oldpath}) {

  const [file ,setfile]=useState([])
  const UserSocket = useRef(null); 

  const activesaison=JSON.parse(localStorage.getItem('saison_active'))


  const handleFileChange = () => {
    if(file.length === 0){
        toast.warning('يرجى اختيار ملف pdf أو صورة')
    }else{
    const selectedFile = file;
    if(!isUpdatingfile){
      uploadFile(selectedFile)

    } else{
      update(selectedFile)
    }
  }
    
   
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    
    formData.append('pdf', file);

    let fileName;
    if (nomfichier) {
      fileName = encodeURIComponent(nomfichier);
    } else {
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      fileName = encodeURIComponent(fileNameWithoutExtension + '_' + id + 'directions');
    }

    const idclub = encodeURIComponent(id);
    const fileType = encodeURIComponent(type);

    axios.defaults.headers.common['X-File-Name'] = fileName;
    axios.defaults.headers.common['idClub'] = idclub;
    axios.defaults.headers.common['type'] = fileType;
    axios.defaults.headers.common['saison'] = activesaison?.id;
    postpdfs(formData)
    .then(async(response) => {
      const data = {
        sender: id, 
        event: `update`,
        description:` ${localStorage.getItem('clubName')} حدث ${type}  ` ,
      
     }
      //UserSocket.current.emit("message", data);
      const resp = await setNotification(data) ;
      if(resp.status === 201){
        toast.success('تم تسجيل الملف بنجاح          ')
      }else{
        toast.error( 'حدث خطأ   ');
      }
    })
      .catch((error) => {
        toast.error("حدث خطأ أثناء تنزيل الملف");
      })
      .finally(() => {
        // Supprimez l'en-tête personnalisé après l'envoi de la requête
        delete axios.defaults.headers.common['X-File-Name'];
        delete axios.defaults.headers.common['idClub'];
        delete axios.defaults.headers.common['type'];
        delete axios.defaults.headers.common['saison'] ;

       window.location.reload();
      });
  }


  const update = (file) => {
    const formData = new FormData();
    formData.append('pdf', file);
    let fileName ; 
    if(nomfichier){
      fileName = encodeURIComponent(nomfichier);
    }
    else{
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
       fileName = encodeURIComponent(fileNameWithoutExtension+'_'+ id+"directions");

    }
    const idclub = encodeURIComponent(id);
    const fileType = encodeURIComponent(type);
    const old_path = encodeURIComponent(oldpath);

    axios.defaults.headers.common['X-File-Name'] = fileName;
    axios.defaults.headers.common['old-path'] = old_path;

    axios.defaults.headers.common['idClub'] = idclub;
    axios.defaults.headers.common['type'] = fileType;
    axios.defaults.headers.common['saison'] = activesaison?.id;

    updatepdfs(formData)
      .then(async(response) => {
        const data = {
          sender: id, 
          event: `update`,
          description:` ${localStorage.getItem('clubName')} حدث ${type}  ` ,
         
       }
       // UserSocket.current.emit("message", data);
       const resp = await setNotification(data) ;
       if(resp.status === 201){
         toast.success('تم تحديث الملف بنجاح          ')
       }else{
         toast.error( 'حدث خطأ   ');
       }
      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du fichier', error);
      })
      .finally(() => {
        // Supprimez l'en-tête personnalisé après l'envoi de la requête
        delete axios.defaults.headers.common['X-File-Name'];
        delete axios.defaults.headers.common['idClub'];
        delete axios.defaults.headers.common['type'];
        delete axios.defaults.headers.common['saison'] ;

       window.location.reload();
      });
  };

  return (
    <div >
  <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />
<input type="file"  id={idinput} onChange={(e)=>setfile(e.target.files[0])}  />
     {isUpdatingfile ? <button onClick={handleFileChange}  >تعديل</button> : <button onClick={handleFileChange}  >حفظ</button>}
    </div>
  );
}
