import React ,{useState,useEffect} from 'react'
import { getclubbyid } from '../../Service/Inscription/ClubInscription/clubinscription'
import { useParams ,useNavigate} from 'react-router-dom';

export default function ClubsPages() {
    const [club , setclub]=useState([]); 
    const { id ,saisonId} = useParams();
    const navigate = useNavigate(); // Get the navigate function
    const idclub = decodeURIComponent(id);
  const listSaison = JSON.parse(localStorage.getItem('listsaison'))
    useEffect(() => {
        const fetchClubs = async()=>{

        const clubResp = await getclubbyid(idclub);
        if(clubResp.status === 200){
            setclub(clubResp.data.data[0]);
           localStorage.setItem('clubName',clubResp.data.data[0].nomClub)
  
      
        }else{
        alert("no federation found")
        }
        
        }  
        fetchClubs();  
    }, []);
  return (
    <div>
                 <p><strong> الموسم الرياضي {listSaison?.find(item => parseInt(item.id) === parseInt(saisonId))?.libelle}</strong></p>

    <div>
      <div className="containerdiv">
      <div className="right-panel">
      <ul>
          <li>
          <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate(`/home/TableauDirectionById/${saisonId}/${idclub}`)}}><p><strong >قائمة  المسيرين     </strong></p></a>

          </li>
          <li>
          <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate(`/home/TableauEntraineurById/${saisonId}/${idclub}`)}}><p><strong >قائمة  المدربين     </strong></p></a>

          </li>
          <li>
          <a style={{textDecoration: 'underline',  color: 'blue', cursor: 'pointer' }} onClick={()=>{navigate(`/home/TableauAtheletteById/${saisonId}/${idclub}`)}}><p><strong >قائمة  الرياضيين     </strong></p></a>

          </li>
         </ul>       </div>
      <hr className="divider" />
      <div className="left-panel">

      <div className="club-form">  

       <form>
   
      <p>
         
          <strong>الشعار:</strong>   
             <img
           src={`data:image/png;base64,${club.logo}`}
           alt="Club Logo"
           width="100"
           height="100"
         />
           </p>
                <p> <strong>الولاية</strong>:  &nbsp;&nbsp; &nbsp;{club.gouvernorat}</p>

            <p><strong>إسم الجمعية أو النادي</strong> :  &nbsp;&nbsp; &nbsp;{club.nomClub}</p>
         <p><strong>العنوان</strong> :  &nbsp;&nbsp; &nbsp;{club.adresse}</p>
        

          <p><strong>رقم التأشيرة</strong>  :  &nbsp;&nbsp; &nbsp;{club.Numvisa}</p>


          <p><strong>تاريخ التأشيرة</strong>  :   &nbsp;&nbsp; &nbsp;{`${new Date(club.dateVisa).getFullYear()}-${(new Date(club.dateVisa).getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${new Date(club.dateVisa).getDate().toString().padStart(2, '0')}`}</p>

    
          <p><strong>الهاتف </strong>:   &nbsp;&nbsp; &nbsp;{club.NmTelephone}</p>

      
          <p><strong>الفاكس</strong>:  &nbsp;&nbsp; &nbsp;{club.fax}</p>
     
          <p><strong>البريد الإلكتروني</strong>  : &nbsp;&nbsp; &nbsp; {club.Email}</p>

       
          <p><strong>الاختصاصات</strong>: &nbsp;&nbsp; &nbsp;{club.specialite}</p>

          

 

          </form>
          </div>

         
      </div>
     
    </div>
      </div>
    
       </div>
  )
}
