import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function insertentaineur(data) {

    try {
        
        const response = await axios.post(`${url}entraineur/insertAntraineur`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getentraineur(saison){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}entraineur/getEntreneur/${idclub}/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function getentraineurbyid(idclub,saisonId){

    try {
       
        const response = await axios.get(`${url}entraineur/getEntreneur/${idclub}/${saisonId}`)
        return response;

    }catch(error){
        return error?.response
    }
  }  

  export async function updateEntraineur (data) {

    try {
        
        const response = await axios.put(`${url}entraineur/updateentraineur`,data)
        return response;

    }catch(error){
        return error?.response
    }
  } 

  export async function deleteEntraineur(id){

    try {
        const response = await axios.delete(`${url}entraineur/delete/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }  

  export async function getAllEntraineurs(saison){
    try {
        const response = await axios.get(`${url}entraineur/getall/${saison}`)
        
        return response;

    }catch(error){
        return error?.response
    }
  }

  
  export async function setvalideEntraineur(type , cin){
    try {
      
        const response = await axios.put(`${url}entraineur/valide/${type}/${cin}`)
        return response;

    }catch(error){
        return error?.response
    }
  }

  export async function setdevalideEntraineur(type , cin){
    try {
      
        const response = await axios.put(`${url}entraineur/devalide/${type}/${cin}`)
        return response;

    }catch(error){
        return error?.response
    }
  }

  export async function setrevalideEntraineur(type , cin){
    try {
      
        const response = await axios.put(`${url}entraineur/revalide/${type}/${cin}`)
        return response;

    }catch(error){
        return error?.response
    }
  }