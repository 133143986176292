import FormulaireClubs from "../Page/Inscription/FormulaireClubs"
import FormulaireLogin from "../Page/Login/FormulaireLogin"
import Home from "../Page/Home/Home"
import Pdfinscription from "../Page/PdfInscription/Pdfinscription"
import Formulairedirection from "../Page/Formulairedirection/Formulairedirection"
import Homeclub from "../Page/homeClub/Homeclub"
import FormulaireEntraineur from "../Page/FormulaireEntraineur/FormulaireEntraineur"
import FormulaireAtheleteApi from "../Page/FormulaireAtlhette/FormulaireAtheleteApi"
import PdfFrais from "../Page/Pdffrais/PdfFrais"
import PdfDirection from "../Page/PfdDirection/PdfDirection"
import ImportPdf from "../Page/ImportPdf/ImportPdf"
import TableauDirection from "../Page/TableauDirection/TableauDirection"
import TableauEntraineur from "../Page/TableauEntraineur/TableauEntraineur"
import TableauAthelette from "../Page/TableauAthlette/TableauAthelette"
import LoginAdmin from "../Page/LoginAdmin/LoginAdmin"
import SecretariatGeneral from "../Page/SecretariatGeneral/SecretariatGeneral"
import QRcode from "../Page/QRcode/QRcode"
import QRScanner from "../Page/QRScanner/QRScanner"
import Certificats from "../Page/Certificats/Certificats"
import QRCodeEntraineur from "../Page/QRCodeEntraineur/QRCodeEntraineur"
import ClubsPages from "../Page/ClubsPages/ClubsPages"
import TableauAtheletteById from "../Page/TableauAthletteByid/TableauAtheletteByid"
import TableauDirectionById from "../Page/TableauDirectionById/TableauDirectionById"
import TableauEntraineurById from "../Page/TableauEntraineurById/TableauEntraineurById"
import Accueil from "../Page/Accueil/Accueil"
import AllEntraineurs from "../Page/AllEntraineurs/AllEntraineurs"
import AllAtheletes from "../Page/AllAtheletes/AllAtheletes"
import AllClubs from "../Page/AllClubs/AllClubs"
import Accueiltest from "../Page/Accueil/Accueil copy"
import Athletefiche from "../Page/Athletefiche/Athletefiche"
import Responsable  from "../Page/Responsable/Responsable"
import Resetpassword from "../Page/Login/Resetpassword"
import Certificat_pdf from "../Page/TableauAthletteByid/Certificat_pdf"
export const Routespublic= [
    { path: '/home/', element: <Home /> },
    { path: '/home/resepassword/:id', element: <Resetpassword /> },
    { path: '/home/inscription', element: <FormulaireClubs /> },
    { path: '/home/login', element: <FormulaireLogin /> },
    { path: '/home/loginadmin', element: <LoginAdmin /> },

    { path: '/home/scanqr', element: <QRScanner /> },

]; 
export const PublicRoute =[
   
    { path: '/', element: <Accueiltest /> },
    { path: '/AllEntraineurs/:saison', element: <AllEntraineurs /> },
    { path: '/AllAtheletes/:saison', element: <AllAtheletes /> },
    { path: '/AllClubs/:saison', element: <AllClubs /> },

]
export const Routesprivate= [
  
    {path:'/pdf/:saisonId' , element:<Pdfinscription/>},
    {path:'/fromulairedirection' , element:<Formulairedirection/>},
    {path:'/homeclub' , element:<Homeclub/>},
    {path:'/FormulaireEntraineur/:dataEn' , element:<FormulaireEntraineur/>},
    {path:'/FormulaireEntraineur' , element:<FormulaireEntraineur/>},

    {path:'/formulaireathelete',element:<FormulaireAtheleteApi/>},
    {path:'/formulaireathelete/:dataEn',element:<FormulaireAtheleteApi/>},

    {path:'/PdfFrais/:saisonId',element:<PdfFrais/>},
    {path:'/PdfDirection',element:<PdfDirection/>},
    {path:'/uploadfichier',element:<ImportPdf/>},
    {path:'/directions/:saisonId',element:<TableauDirection/>},
    {path:'/entraineurs/:saisonId',element:<TableauEntraineur/>},
    {path: '/athelete/:saisonId',element:<TableauAthelette/>},
    { path: '/qr/:data', element: <QRcode /> },
    {path: '/QRCodeEntraineur/:data' ,  element :<QRCodeEntraineur/> },


]

export const RoutesFedreation=[
    {path:'/home/SecretariatGeneral' , element:<SecretariatGeneral/>},
    {path:'/home/ClubsPages/:saisonId/:id' , element:<ClubsPages/>},
    {path:'/home/TableauAtheletteById/:saisonId/:id' , element:<TableauAtheletteById/>},
    {path:'/home/TableauDirectionById/:saisonId/:id' , element:<TableauDirectionById/>},
    {path:'/home/TableauEntraineurById/:saisonId/:id' , element:<TableauEntraineurById/>},
    {path:'/home/responsable' , element:<Responsable/>},
    { path: '/home/AllEntraineurs/:saison', element: <AllEntraineurs /> },
    { path: '/home/AllAtheletes/:saison', element: <AllAtheletes /> },
    { path: '/home/AllClubs/:saison', element: <AllClubs /> },
    {path: '/Certificat_pdf/:data' ,  element :<Certificat_pdf/> },



]/*    { path: '/Certificats/:data', element: <Certificats /> },
*/