import React, { useEffect, useState } from 'react'
import { confirmationfinale, getallclubs } from '../../Service/Federation/FederationApi'
import { useNavigate } from "react-router-dom";
export default function ClubsTables({saison,listSaison,setSaison}) {
  const navigate = useNavigate();

  const { REACT_APP_API_URL } = process.env;
  const url = REACT_APP_API_URL
  const [clubs, setclubs] = useState([]);
  const [filter, setFilter] = useState({
    name: '',
    address: '',
    specialties: '',
    governorate: '',
  
  });
  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getallclubs(saison);
      if (clubResp.status === 200) {
        if(clubResp.data.data && clubResp.data.data.length !== 0){
          setclubs(clubResp.data.data);

        }


      } else {
        alert("no club found")
      }

    }
    fetchClubs();
  }, [saison]);
  const handelgetpdfs = async (idclub) => {
    const id = encodeURIComponent(idclub);
    const newTab = window.open(`${url}federation/getpdfs/${saison}/${id}`, '_blank');
  
    if (newTab) {
      // If the new tab was successfully opened, you can focus on it (optional)
      newTab.focus();
    } else {
      alert('Popup blocker prevented opening a new tab.');
    }
  };
  
  const handelnavigete = (id) => {

    navigate(`/home/ClubsPages/${saison}/${encodeURIComponent(id)}`)
  }
  const confirmation = async(idclub)=>{
    const responsible =localStorage.getItem('email');

    try {
      const resp = await confirmationfinale(responsible,idclub);

      if (resp.status === 200) {
       window.location.reload();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }

  const filteredClubs = clubs.filter((club) => {
    const { name, address, specialties, governorate,valide } = filter;
    return (
      club.nomClub.toLowerCase().includes(name.toLowerCase()) &&
      club.adresse.toLowerCase().includes(address.toLowerCase()) &&
      (specialties === '' || club.specialite.toLowerCase().includes(specialties.toLowerCase())) &&
      (governorate === '' || club.gouvernorat.toLowerCase().includes(governorate.toLowerCase()))

    );
  });
  return (
    <div style={{ 'backgroundColor': 'white', 'marginTop': '15px' }}>


      <header>
        <div className="image-container">

          <div className="info-container row">

            <p className='col-3'><strong >قائمة  الأندية     </strong></p>

            <p className='col-6'><strong> الموسم الرياضي<select value={saison} onChange={(e)=>setSaison(e.target.value)}>
              {listSaison && listSaison.map(item => (
                <option key={item.id} value={item.id}>
                    {item.libelle}
                </option>
              ))}

            </select></strong> </p>
            <p  className='col-3' > <strong>  العدد الجملي </strong> {filteredClubs.length}</p>


          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ "marginTop": "20px", 'width': '400px', 'marginLeft': '25px' }}>
              <input
                type="text"
                placeholder="تصفية حسب اسم النادي          "
                value={filter.name}
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              />
            </div>
         
            <div style={{ "marginTop": "20px", 'width': '400px', 'marginLeft': '25px' }}>
              <input
                type="text"
                placeholder="تصفية حسب العنوان          "
                value={filter.address}
                onChange={(e) => setFilter({ ...filter, address: e.target.value })}
              />
            </div>



          </div>
        </div>

      </header>
      <div style={{ 'height': '500px', 'overflowY': 'auto' }}>
        <table >
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className='center-text'>النادي</th>
              <th className='center-text'> رمز تسجيل النادي</th>
              <th className='center-text'> حالة </th>

            </tr>
          </thead>
          <tbody>
            {filteredClubs&& filteredClubs.length!==0 && filteredClubs.map((club,i) => (
              <tr key={club.id}>
                <td>{i+1}</td>
                {club.ancien === 1 ? (
                  <td className='center-text'> تسجيل  قديم</td>

                ) :
                  <td className='center-text' >تسجيل جديد           </td>

                }
                <td className='center-text ' onClick={() => { handelnavigete(club.numInscription) }}><p className='lien-bleu-souligne'>{club.nomClub}</p></td>
                <td className='center-text '><p >{club.numInscription}</p></td>

                <td className='center-text'>{club.valide === -1 ? 'رفض' : club.valide === 1 ? '  تم القبول الاول' : club.valide === 0 ? 'لم يدرس بعد' : club.valide > 1 ?'منخرط' :null}</td>

                <td className='center-text' >{club.valide !== 0 ? (<a onClick={() => { handelgetpdfs(club.numInscription) }} style={{ "color": "blue" }}>انظر الوثيقة</a>) : null}
              
                </td>


                {club.valide === 1  && localStorage.getItem('IDrole')=== "FTGYM-1" ? (
                    <td><button onClick={()=>confirmation(club.numInscription)}>
                      قبول الانخراط        
                    </button></td>
                  ) : <td></td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div> </div>
  )
}
