import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getcategories } from '../../utils/Categories';
import { getathelete, updateAthlete, deleteAthlete } from '../../Service/Athlete/AthleteApi';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
export default function TableauAthelette() {
  const navigate = useNavigate();
  const [specialite, setspecialite] = useState([])
  const [persons, setPersons] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);
  const valide = localStorage.getItem('validation')
  const { saisonId } = useParams()
  const listSaison = JSON.parse(localStorage.getItem('listsaison'))
  const activesaison = JSON.parse(localStorage.getItem('saison_active'))
  const setpInscription = localStorage.getItem('setpInscription')
  useEffect(() => {
    const fetchsDiscipline = async () => {
      try {
        const respdiscipline = await Getdiscipline();
        setspecialite(respdiscipline.data.data)
      } catch (error) {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")

      }
    }
    fetchsDiscipline();
  }, []);

  useEffect(() => {
    const fetchentraineur = async () => {
      const response = await getathelete(saisonId);
      if (response.status === 200) {

        const respdiscipline = await Getdiscipline();


        response.data.data.forEach(element => {
          const specialiteListe = [];
          const specialiteFrListe = [];

          const categoriesAges = [];
          respdiscipline.data.data.forEach(item => {
            if (element[item.libellerFR] !== null) {

              specialiteListe.push(item['LibellerAR']);
              specialiteFrListe.push(item['libellerFR'])
              categoriesAges.push(element[`${[item.libellerFR]}_CA`])
            }
          });
          element.specialites = specialiteListe.join(',');
          const categoriesAgeslist = categoriesAges.filter(item => item !== '' && item !== null);
          element.specialiteFrListe=specialiteFrListe
          element.categories_age = categoriesAgeslist.join(',')
        });

        setPersons(response.data.data);
      } else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")

      }

    }
    fetchentraineur();
  }, []);

  const handleChangeSpecialite = async (id, specialiteValue, Arlibeller, check) => {
    const updatedPersons = await Promise.all(
      persons.map(async (person) => {
        if (person.id === id) {
          if (check) {
            if (!person.specialite?.includes(Arlibeller)) {

              const cateAge = await getcategories(person.datenaissance, [specialiteValue]);
              console.log(cateAge)
              if (cateAge) {
                person.specialites = person.specialites?.split(',');
                person.specialites.push(Arlibeller);
                person.specialites = person.specialites.join(',');

                person[specialiteValue] = `FTGYM_Licence-${specialiteValue}_${person.nom}_${localStorage.getItem('idunique')}_${person.idunique}`
                person[`${[specialiteValue]}_CA`] = cateAge || null;

              }
              else {
                toast.warning("العمر لا يتوافق مع أي فئة عمرية  ")

              }
            }
          } else {
            person.specialites = person.specialites.split(',');

            person.specialites = person.specialites.filter((sp) => sp !== Arlibeller);

            person.specialites = person.specialites.join(',');

            person[`${[specialiteValue]}`] = null;
            person[`${[specialiteValue]}_CA`] = null;
          }
        }
        return person;
      })
    );

    setPersons(updatedPersons);
  };


  const handleEditClick = (id) => {
    setEditableRowId(id);
    const updatedPerson = persons.find((person) => person.id === id);

    navigate(`/formulaireathelete/${encodeURIComponent(JSON.stringify(updatedPerson))}`)
  };

  const handleSaveClick = async (id) => {


    const updatedPerson = persons.find((person) => person.id === id);

    let nbr_licences = 0
    if (updatedPerson.specialites !== '' && updatedPerson.specialites !== ',') {
      const newlist = updatedPerson.specialites.split(',').filter(item => item !== '')
      nbr_licences = newlist.length;

    }
    updatedPerson.nbrlicence = nbr_licences

    if (nbr_licences === 0) {
      toast.warning(`الرجاء اختيار التخصص الرياضي `)

    } else {
      delete updatedPerson.categories_age;

      delete updatedPerson.specialites;
      delete updatedPerson.image1;
      const resp = await updateAthlete(updatedPerson);

      if (resp.status === 201) {
        window.location.reload();
      }
      else {
        toast.error("نحن آسفون، يبدو أن هناك مشكلة")

      }
    }


  };

  const handeldelete = async (id) => {

    const resp = await deleteAthlete(id);
    if (resp.status === 200) {
      window.location.reload();

    } else {
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
  }
  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const updatedPersons = persons.map((person) => {
      if (person.id === id) {
        return { ...person, [name]: value };
      } else {
        return person;
      }
    });

    setPersons(updatedPersons);
  };
  const getlicence = (item) => {
    navigate(`/qr/${encodeURIComponent(JSON.stringify(item))}`)
  }
  const updateStep = async () => {
    const numInscription = localStorage.getItem('idunique')
    const data = { step: 4, numInscription: numInscription, saison: saisonId };
    const clubResp = await updatesteps(data);
    if (clubResp.status === 200) {
      localStorage.setItem('setpInscription',4)
      window.location.reload()


    }
  }
  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

      <header>
        <div className="image-container">

          <div className="info-container">

            <p><strong >قائمة  الرياضيين     </strong></p>

            <p><strong> الموسم الرياضي {listSaison?.find(item => parseInt(item.id) === parseInt(saisonId))?.libelle}</strong></p>
          </div>

        </div>

      </header>
      {(parseInt(saisonId) === parseInt(activesaison.id) && parseInt(valide) < 2) ? (<div className='row mr-5' onClick={() => { navigate('/formulaireathelete') }}><button>  إضافة الرياضيين</button></div>) : null}

      {persons.length}
      <table>
        <thead>
          <tr>
            <th></th>
            <th className='center-text'>الاسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>تاريخ الولادة</th>
            <th className='center-text'>الفئة العمرية</th>
            <th className='center-text'> الاختصاصات</th>
          </tr>
        </thead>
        <tbody>
          {persons.map((person, index) => (
            <tr key={person.id}>
              <td>{index + 1}</td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="nom"
                    value={person.nom}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}
                  />
                ) : (
                  person.nom
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="text"
                    name="prenom"
                    value={person.prenom}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.prenom
                )}
              </td>


              <td className='center-text'>
                {editableRowId === person.id ? (
                  <input
                    type="date"
                    name="datenaissance"
                    value={person.datenaissance}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  `${new Date(person.datenaissance).getFullYear()}-${(new Date(person.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${new Date(person.datenaissance).getDate().toString().padStart(2, '0')}`
                )}
              </td>
              <td className='center-text'>
                {false ? (
                  <input
                    type="text"
                    name="lieunaissance"
                    value={person.categories_age}
                    onChange={(e) => handleChange(e, person.id)}
                    style={{ width: '100px' }}

                  />
                ) : (
                  person.categories_age
                )}
              </td>
              <td className='center-text'>
                {editableRowId === person.id ? (
                  <div>
                    {specialite.map((item, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={`parcour_${item.libellerFR}`}
                          name={`specialite[${index}]`}
                          value={item.libellerFR}
                          checked={person.specialites?.includes(item.LibellerAR)}
                          onChange={(e) => handleChangeSpecialite(person.id, item.libellerFR, item.LibellerAR, e.target.checked)}
                        />
                        <label htmlFor={`parcour_${item.libellerFR}`}>{item.LibellerAR}</label>
                      </div>
                    ))}
                  </div>
                ) : (
                  person.specialites
                )}
              </td>


              {parseInt(valide) < 2 ? (
                <>
                  <td className='center-text'>
                    {editableRowId === person.id ? (
                      <button onClick={() => handleSaveClick(person.id)}>حفظ</button>
                    ) : (
                      <button onClick={() => handleEditClick(person.id)}>تعديل</button>
                    )}
                  </td>

                  <td> <button onClick={() => handeldelete(person.id)}>مسح </button> </td>
                </>
              ) :
                <td>               <td> <button onClick={() => getlicence(person)}>الحصول على ترخيص </button> </td>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
      {parseInt(saisonId) === parseInt(activesaison.id) && parseInt(setpInscription) === 3 && <div className='row  col-4 mt-2' onClick={updateStep}><button>تأكيد قائمة المدربين      </button></div>
      }
    </div>
  );

}
